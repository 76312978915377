const tokens = {
  "colors.$hiContrast": {
    "value": "var(--colors-\\$hi-contrast)",
    "variable": "var(--colors-\\$hi-contrast)"
  },
  "colors.$loContrast": {
    "value": "var(--colors-\\$lo-contrast)",
    "variable": "var(--colors-\\$lo-contrast)"
  },
  "colors.$transparentPanel": {
    "value": "var(--colors-\\$transparent-panel)",
    "variable": "var(--colors-\\$transparent-panel)"
  },
  "colors.$overlay1": {
    "value": "var(--colors-\\$overlay1)",
    "variable": "var(--colors-\\$overlay1)"
  },
  "colors.$overlay2": {
    "value": "var(--colors-\\$overlay2)",
    "variable": "var(--colors-\\$overlay2)"
  },
  "colors.$overlay3": {
    "value": "var(--colors-\\$overlay3)",
    "variable": "var(--colors-\\$overlay3)"
  },
  "colors.$overlay4": {
    "value": "var(--colors-\\$overlay4)",
    "variable": "var(--colors-\\$overlay4)"
  },
  "colors.$overlay5": {
    "value": "var(--colors-\\$overlay5)",
    "variable": "var(--colors-\\$overlay5)"
  },
  "colors.$overlay6": {
    "value": "var(--colors-\\$overlay6)",
    "variable": "var(--colors-\\$overlay6)"
  },
  "colors.$overlay7": {
    "value": "var(--colors-\\$overlay7)",
    "variable": "var(--colors-\\$overlay7)"
  },
  "colors.$overlay8": {
    "value": "var(--colors-\\$overlay8)",
    "variable": "var(--colors-\\$overlay8)"
  },
  "colors.$overlay9": {
    "value": "var(--colors-\\$overlay9)",
    "variable": "var(--colors-\\$overlay9)"
  },
  "colors.$overlay10": {
    "value": "var(--colors-\\$overlay10)",
    "variable": "var(--colors-\\$overlay10)"
  },
  "colors.$overlay11": {
    "value": "var(--colors-\\$overlay11)",
    "variable": "var(--colors-\\$overlay11)"
  },
  "colors.$overlay12": {
    "value": "var(--colors-\\$overlay12)",
    "variable": "var(--colors-\\$overlay12)"
  },
  "colors.$gray1": {
    "value": "var(--colors-\\$gray1)",
    "variable": "var(--colors-\\$gray1)"
  },
  "colors.$gray2": {
    "value": "var(--colors-\\$gray2)",
    "variable": "var(--colors-\\$gray2)"
  },
  "colors.$gray3": {
    "value": "var(--colors-\\$gray3)",
    "variable": "var(--colors-\\$gray3)"
  },
  "colors.$gray4": {
    "value": "var(--colors-\\$gray4)",
    "variable": "var(--colors-\\$gray4)"
  },
  "colors.$gray5": {
    "value": "var(--colors-\\$gray5)",
    "variable": "var(--colors-\\$gray5)"
  },
  "colors.$gray6": {
    "value": "var(--colors-\\$gray6)",
    "variable": "var(--colors-\\$gray6)"
  },
  "colors.$gray7": {
    "value": "var(--colors-\\$gray7)",
    "variable": "var(--colors-\\$gray7)"
  },
  "colors.$gray8": {
    "value": "var(--colors-\\$gray8)",
    "variable": "var(--colors-\\$gray8)"
  },
  "colors.$gray9": {
    "value": "var(--colors-\\$gray9)",
    "variable": "var(--colors-\\$gray9)"
  },
  "colors.$gray10": {
    "value": "var(--colors-\\$gray10)",
    "variable": "var(--colors-\\$gray10)"
  },
  "colors.$gray11": {
    "value": "var(--colors-\\$gray11)",
    "variable": "var(--colors-\\$gray11)"
  },
  "colors.$gray12": {
    "value": "var(--colors-\\$gray12)",
    "variable": "var(--colors-\\$gray12)"
  },
  "colors.$mauve1": {
    "value": "var(--colors-\\$mauve1)",
    "variable": "var(--colors-\\$mauve1)"
  },
  "colors.$mauve2": {
    "value": "var(--colors-\\$mauve2)",
    "variable": "var(--colors-\\$mauve2)"
  },
  "colors.$mauve3": {
    "value": "var(--colors-\\$mauve3)",
    "variable": "var(--colors-\\$mauve3)"
  },
  "colors.$mauve4": {
    "value": "var(--colors-\\$mauve4)",
    "variable": "var(--colors-\\$mauve4)"
  },
  "colors.$mauve5": {
    "value": "var(--colors-\\$mauve5)",
    "variable": "var(--colors-\\$mauve5)"
  },
  "colors.$mauve6": {
    "value": "var(--colors-\\$mauve6)",
    "variable": "var(--colors-\\$mauve6)"
  },
  "colors.$mauve7": {
    "value": "var(--colors-\\$mauve7)",
    "variable": "var(--colors-\\$mauve7)"
  },
  "colors.$mauve8": {
    "value": "var(--colors-\\$mauve8)",
    "variable": "var(--colors-\\$mauve8)"
  },
  "colors.$mauve9": {
    "value": "var(--colors-\\$mauve9)",
    "variable": "var(--colors-\\$mauve9)"
  },
  "colors.$mauve10": {
    "value": "var(--colors-\\$mauve10)",
    "variable": "var(--colors-\\$mauve10)"
  },
  "colors.$mauve11": {
    "value": "var(--colors-\\$mauve11)",
    "variable": "var(--colors-\\$mauve11)"
  },
  "colors.$mauve12": {
    "value": "var(--colors-\\$mauve12)",
    "variable": "var(--colors-\\$mauve12)"
  },
  "colors.$slate1": {
    "value": "var(--colors-\\$slate1)",
    "variable": "var(--colors-\\$slate1)"
  },
  "colors.$slate2": {
    "value": "var(--colors-\\$slate2)",
    "variable": "var(--colors-\\$slate2)"
  },
  "colors.$slate3": {
    "value": "var(--colors-\\$slate3)",
    "variable": "var(--colors-\\$slate3)"
  },
  "colors.$slate4": {
    "value": "var(--colors-\\$slate4)",
    "variable": "var(--colors-\\$slate4)"
  },
  "colors.$slate5": {
    "value": "var(--colors-\\$slate5)",
    "variable": "var(--colors-\\$slate5)"
  },
  "colors.$slate6": {
    "value": "var(--colors-\\$slate6)",
    "variable": "var(--colors-\\$slate6)"
  },
  "colors.$slate7": {
    "value": "var(--colors-\\$slate7)",
    "variable": "var(--colors-\\$slate7)"
  },
  "colors.$slate8": {
    "value": "var(--colors-\\$slate8)",
    "variable": "var(--colors-\\$slate8)"
  },
  "colors.$slate9": {
    "value": "var(--colors-\\$slate9)",
    "variable": "var(--colors-\\$slate9)"
  },
  "colors.$slate10": {
    "value": "var(--colors-\\$slate10)",
    "variable": "var(--colors-\\$slate10)"
  },
  "colors.$slate11": {
    "value": "var(--colors-\\$slate11)",
    "variable": "var(--colors-\\$slate11)"
  },
  "colors.$slate12": {
    "value": "var(--colors-\\$slate12)",
    "variable": "var(--colors-\\$slate12)"
  },
  "colors.$sage1": {
    "value": "var(--colors-\\$sage1)",
    "variable": "var(--colors-\\$sage1)"
  },
  "colors.$sage2": {
    "value": "var(--colors-\\$sage2)",
    "variable": "var(--colors-\\$sage2)"
  },
  "colors.$sage3": {
    "value": "var(--colors-\\$sage3)",
    "variable": "var(--colors-\\$sage3)"
  },
  "colors.$sage4": {
    "value": "var(--colors-\\$sage4)",
    "variable": "var(--colors-\\$sage4)"
  },
  "colors.$sage5": {
    "value": "var(--colors-\\$sage5)",
    "variable": "var(--colors-\\$sage5)"
  },
  "colors.$sage6": {
    "value": "var(--colors-\\$sage6)",
    "variable": "var(--colors-\\$sage6)"
  },
  "colors.$sage7": {
    "value": "var(--colors-\\$sage7)",
    "variable": "var(--colors-\\$sage7)"
  },
  "colors.$sage8": {
    "value": "var(--colors-\\$sage8)",
    "variable": "var(--colors-\\$sage8)"
  },
  "colors.$sage9": {
    "value": "var(--colors-\\$sage9)",
    "variable": "var(--colors-\\$sage9)"
  },
  "colors.$sage10": {
    "value": "var(--colors-\\$sage10)",
    "variable": "var(--colors-\\$sage10)"
  },
  "colors.$sage11": {
    "value": "var(--colors-\\$sage11)",
    "variable": "var(--colors-\\$sage11)"
  },
  "colors.$sage12": {
    "value": "var(--colors-\\$sage12)",
    "variable": "var(--colors-\\$sage12)"
  },
  "colors.$olive1": {
    "value": "var(--colors-\\$olive1)",
    "variable": "var(--colors-\\$olive1)"
  },
  "colors.$olive2": {
    "value": "var(--colors-\\$olive2)",
    "variable": "var(--colors-\\$olive2)"
  },
  "colors.$olive3": {
    "value": "var(--colors-\\$olive3)",
    "variable": "var(--colors-\\$olive3)"
  },
  "colors.$olive4": {
    "value": "var(--colors-\\$olive4)",
    "variable": "var(--colors-\\$olive4)"
  },
  "colors.$olive5": {
    "value": "var(--colors-\\$olive5)",
    "variable": "var(--colors-\\$olive5)"
  },
  "colors.$olive6": {
    "value": "var(--colors-\\$olive6)",
    "variable": "var(--colors-\\$olive6)"
  },
  "colors.$olive7": {
    "value": "var(--colors-\\$olive7)",
    "variable": "var(--colors-\\$olive7)"
  },
  "colors.$olive8": {
    "value": "var(--colors-\\$olive8)",
    "variable": "var(--colors-\\$olive8)"
  },
  "colors.$olive9": {
    "value": "var(--colors-\\$olive9)",
    "variable": "var(--colors-\\$olive9)"
  },
  "colors.$olive10": {
    "value": "var(--colors-\\$olive10)",
    "variable": "var(--colors-\\$olive10)"
  },
  "colors.$olive11": {
    "value": "var(--colors-\\$olive11)",
    "variable": "var(--colors-\\$olive11)"
  },
  "colors.$olive12": {
    "value": "var(--colors-\\$olive12)",
    "variable": "var(--colors-\\$olive12)"
  },
  "colors.$sand1": {
    "value": "var(--colors-\\$sand1)",
    "variable": "var(--colors-\\$sand1)"
  },
  "colors.$sand2": {
    "value": "var(--colors-\\$sand2)",
    "variable": "var(--colors-\\$sand2)"
  },
  "colors.$sand3": {
    "value": "var(--colors-\\$sand3)",
    "variable": "var(--colors-\\$sand3)"
  },
  "colors.$sand4": {
    "value": "var(--colors-\\$sand4)",
    "variable": "var(--colors-\\$sand4)"
  },
  "colors.$sand5": {
    "value": "var(--colors-\\$sand5)",
    "variable": "var(--colors-\\$sand5)"
  },
  "colors.$sand6": {
    "value": "var(--colors-\\$sand6)",
    "variable": "var(--colors-\\$sand6)"
  },
  "colors.$sand7": {
    "value": "var(--colors-\\$sand7)",
    "variable": "var(--colors-\\$sand7)"
  },
  "colors.$sand8": {
    "value": "var(--colors-\\$sand8)",
    "variable": "var(--colors-\\$sand8)"
  },
  "colors.$sand9": {
    "value": "var(--colors-\\$sand9)",
    "variable": "var(--colors-\\$sand9)"
  },
  "colors.$sand10": {
    "value": "var(--colors-\\$sand10)",
    "variable": "var(--colors-\\$sand10)"
  },
  "colors.$sand11": {
    "value": "var(--colors-\\$sand11)",
    "variable": "var(--colors-\\$sand11)"
  },
  "colors.$sand12": {
    "value": "var(--colors-\\$sand12)",
    "variable": "var(--colors-\\$sand12)"
  },
  "colors.$grayDarkA1": {
    "value": "var(--colors-\\$gray-dark-a1)",
    "variable": "var(--colors-\\$gray-dark-a1)"
  },
  "colors.$grayDarkA2": {
    "value": "var(--colors-\\$gray-dark-a2)",
    "variable": "var(--colors-\\$gray-dark-a2)"
  },
  "colors.$grayDarkA3": {
    "value": "var(--colors-\\$gray-dark-a3)",
    "variable": "var(--colors-\\$gray-dark-a3)"
  },
  "colors.$grayDarkA4": {
    "value": "var(--colors-\\$gray-dark-a4)",
    "variable": "var(--colors-\\$gray-dark-a4)"
  },
  "colors.$grayDarkA5": {
    "value": "var(--colors-\\$gray-dark-a5)",
    "variable": "var(--colors-\\$gray-dark-a5)"
  },
  "colors.$grayDarkA6": {
    "value": "var(--colors-\\$gray-dark-a6)",
    "variable": "var(--colors-\\$gray-dark-a6)"
  },
  "colors.$grayDarkA7": {
    "value": "var(--colors-\\$gray-dark-a7)",
    "variable": "var(--colors-\\$gray-dark-a7)"
  },
  "colors.$grayDarkA8": {
    "value": "var(--colors-\\$gray-dark-a8)",
    "variable": "var(--colors-\\$gray-dark-a8)"
  },
  "colors.$grayDarkA9": {
    "value": "var(--colors-\\$gray-dark-a9)",
    "variable": "var(--colors-\\$gray-dark-a9)"
  },
  "colors.$grayDarkA10": {
    "value": "var(--colors-\\$gray-dark-a10)",
    "variable": "var(--colors-\\$gray-dark-a10)"
  },
  "colors.$grayDarkA11": {
    "value": "var(--colors-\\$gray-dark-a11)",
    "variable": "var(--colors-\\$gray-dark-a11)"
  },
  "colors.$grayDarkA12": {
    "value": "var(--colors-\\$gray-dark-a12)",
    "variable": "var(--colors-\\$gray-dark-a12)"
  },
  "colors.$violet1": {
    "value": "var(--colors-\\$violet1)",
    "variable": "var(--colors-\\$violet1)"
  },
  "colors.$violet2": {
    "value": "var(--colors-\\$violet2)",
    "variable": "var(--colors-\\$violet2)"
  },
  "colors.$violet3": {
    "value": "var(--colors-\\$violet3)",
    "variable": "var(--colors-\\$violet3)"
  },
  "colors.$violet4": {
    "value": "var(--colors-\\$violet4)",
    "variable": "var(--colors-\\$violet4)"
  },
  "colors.$violet5": {
    "value": "var(--colors-\\$violet5)",
    "variable": "var(--colors-\\$violet5)"
  },
  "colors.$violet6": {
    "value": "var(--colors-\\$violet6)",
    "variable": "var(--colors-\\$violet6)"
  },
  "colors.$violet7": {
    "value": "var(--colors-\\$violet7)",
    "variable": "var(--colors-\\$violet7)"
  },
  "colors.$violet8": {
    "value": "var(--colors-\\$violet8)",
    "variable": "var(--colors-\\$violet8)"
  },
  "colors.$violet9": {
    "value": "var(--colors-\\$violet9)",
    "variable": "var(--colors-\\$violet9)"
  },
  "colors.$violet10": {
    "value": "var(--colors-\\$violet10)",
    "variable": "var(--colors-\\$violet10)"
  },
  "colors.$violet11": {
    "value": "var(--colors-\\$violet11)",
    "variable": "var(--colors-\\$violet11)"
  },
  "colors.$violet12": {
    "value": "var(--colors-\\$violet12)",
    "variable": "var(--colors-\\$violet12)"
  },
  "colors.$indigo1": {
    "value": "var(--colors-\\$indigo1)",
    "variable": "var(--colors-\\$indigo1)"
  },
  "colors.$indigo2": {
    "value": "var(--colors-\\$indigo2)",
    "variable": "var(--colors-\\$indigo2)"
  },
  "colors.$indigo3": {
    "value": "var(--colors-\\$indigo3)",
    "variable": "var(--colors-\\$indigo3)"
  },
  "colors.$indigo4": {
    "value": "var(--colors-\\$indigo4)",
    "variable": "var(--colors-\\$indigo4)"
  },
  "colors.$indigo5": {
    "value": "var(--colors-\\$indigo5)",
    "variable": "var(--colors-\\$indigo5)"
  },
  "colors.$indigo6": {
    "value": "var(--colors-\\$indigo6)",
    "variable": "var(--colors-\\$indigo6)"
  },
  "colors.$indigo7": {
    "value": "var(--colors-\\$indigo7)",
    "variable": "var(--colors-\\$indigo7)"
  },
  "colors.$indigo8": {
    "value": "var(--colors-\\$indigo8)",
    "variable": "var(--colors-\\$indigo8)"
  },
  "colors.$indigo9": {
    "value": "var(--colors-\\$indigo9)",
    "variable": "var(--colors-\\$indigo9)"
  },
  "colors.$indigo10": {
    "value": "var(--colors-\\$indigo10)",
    "variable": "var(--colors-\\$indigo10)"
  },
  "colors.$indigo11": {
    "value": "var(--colors-\\$indigo11)",
    "variable": "var(--colors-\\$indigo11)"
  },
  "colors.$indigo12": {
    "value": "var(--colors-\\$indigo12)",
    "variable": "var(--colors-\\$indigo12)"
  },
  "colors.$blue1": {
    "value": "var(--colors-\\$blue1)",
    "variable": "var(--colors-\\$blue1)"
  },
  "colors.$blue2": {
    "value": "var(--colors-\\$blue2)",
    "variable": "var(--colors-\\$blue2)"
  },
  "colors.$blue3": {
    "value": "var(--colors-\\$blue3)",
    "variable": "var(--colors-\\$blue3)"
  },
  "colors.$blue4": {
    "value": "var(--colors-\\$blue4)",
    "variable": "var(--colors-\\$blue4)"
  },
  "colors.$blue5": {
    "value": "var(--colors-\\$blue5)",
    "variable": "var(--colors-\\$blue5)"
  },
  "colors.$blue6": {
    "value": "var(--colors-\\$blue6)",
    "variable": "var(--colors-\\$blue6)"
  },
  "colors.$blue7": {
    "value": "var(--colors-\\$blue7)",
    "variable": "var(--colors-\\$blue7)"
  },
  "colors.$blue8": {
    "value": "var(--colors-\\$blue8)",
    "variable": "var(--colors-\\$blue8)"
  },
  "colors.$blue9": {
    "value": "var(--colors-\\$blue9)",
    "variable": "var(--colors-\\$blue9)"
  },
  "colors.$blue10": {
    "value": "var(--colors-\\$blue10)",
    "variable": "var(--colors-\\$blue10)"
  },
  "colors.$blue11": {
    "value": "var(--colors-\\$blue11)",
    "variable": "var(--colors-\\$blue11)"
  },
  "colors.$blue12": {
    "value": "var(--colors-\\$blue12)",
    "variable": "var(--colors-\\$blue12)"
  },
  "colors.$grayA1": {
    "value": "var(--colors-\\$gray-a1)",
    "variable": "var(--colors-\\$gray-a1)"
  },
  "colors.$grayA2": {
    "value": "var(--colors-\\$gray-a2)",
    "variable": "var(--colors-\\$gray-a2)"
  },
  "colors.$grayA3": {
    "value": "var(--colors-\\$gray-a3)",
    "variable": "var(--colors-\\$gray-a3)"
  },
  "colors.$grayA4": {
    "value": "var(--colors-\\$gray-a4)",
    "variable": "var(--colors-\\$gray-a4)"
  },
  "colors.$grayA5": {
    "value": "var(--colors-\\$gray-a5)",
    "variable": "var(--colors-\\$gray-a5)"
  },
  "colors.$grayA6": {
    "value": "var(--colors-\\$gray-a6)",
    "variable": "var(--colors-\\$gray-a6)"
  },
  "colors.$grayA7": {
    "value": "var(--colors-\\$gray-a7)",
    "variable": "var(--colors-\\$gray-a7)"
  },
  "colors.$grayA8": {
    "value": "var(--colors-\\$gray-a8)",
    "variable": "var(--colors-\\$gray-a8)"
  },
  "colors.$grayA9": {
    "value": "var(--colors-\\$gray-a9)",
    "variable": "var(--colors-\\$gray-a9)"
  },
  "colors.$grayA10": {
    "value": "var(--colors-\\$gray-a10)",
    "variable": "var(--colors-\\$gray-a10)"
  },
  "colors.$grayA11": {
    "value": "var(--colors-\\$gray-a11)",
    "variable": "var(--colors-\\$gray-a11)"
  },
  "colors.$grayA12": {
    "value": "var(--colors-\\$gray-a12)",
    "variable": "var(--colors-\\$gray-a12)"
  },
  "colors.$mauveA1": {
    "value": "var(--colors-\\$mauve-a1)",
    "variable": "var(--colors-\\$mauve-a1)"
  },
  "colors.$mauveA2": {
    "value": "var(--colors-\\$mauve-a2)",
    "variable": "var(--colors-\\$mauve-a2)"
  },
  "colors.$mauveA3": {
    "value": "var(--colors-\\$mauve-a3)",
    "variable": "var(--colors-\\$mauve-a3)"
  },
  "colors.$mauveA4": {
    "value": "var(--colors-\\$mauve-a4)",
    "variable": "var(--colors-\\$mauve-a4)"
  },
  "colors.$mauveA5": {
    "value": "var(--colors-\\$mauve-a5)",
    "variable": "var(--colors-\\$mauve-a5)"
  },
  "colors.$mauveA6": {
    "value": "var(--colors-\\$mauve-a6)",
    "variable": "var(--colors-\\$mauve-a6)"
  },
  "colors.$mauveA7": {
    "value": "var(--colors-\\$mauve-a7)",
    "variable": "var(--colors-\\$mauve-a7)"
  },
  "colors.$mauveA8": {
    "value": "var(--colors-\\$mauve-a8)",
    "variable": "var(--colors-\\$mauve-a8)"
  },
  "colors.$mauveA9": {
    "value": "var(--colors-\\$mauve-a9)",
    "variable": "var(--colors-\\$mauve-a9)"
  },
  "colors.$mauveA10": {
    "value": "var(--colors-\\$mauve-a10)",
    "variable": "var(--colors-\\$mauve-a10)"
  },
  "colors.$mauveA11": {
    "value": "var(--colors-\\$mauve-a11)",
    "variable": "var(--colors-\\$mauve-a11)"
  },
  "colors.$mauveA12": {
    "value": "var(--colors-\\$mauve-a12)",
    "variable": "var(--colors-\\$mauve-a12)"
  },
  "colors.$slateA1": {
    "value": "var(--colors-\\$slate-a1)",
    "variable": "var(--colors-\\$slate-a1)"
  },
  "colors.$slateA2": {
    "value": "var(--colors-\\$slate-a2)",
    "variable": "var(--colors-\\$slate-a2)"
  },
  "colors.$slateA3": {
    "value": "var(--colors-\\$slate-a3)",
    "variable": "var(--colors-\\$slate-a3)"
  },
  "colors.$slateA4": {
    "value": "var(--colors-\\$slate-a4)",
    "variable": "var(--colors-\\$slate-a4)"
  },
  "colors.$slateA5": {
    "value": "var(--colors-\\$slate-a5)",
    "variable": "var(--colors-\\$slate-a5)"
  },
  "colors.$slateA6": {
    "value": "var(--colors-\\$slate-a6)",
    "variable": "var(--colors-\\$slate-a6)"
  },
  "colors.$slateA7": {
    "value": "var(--colors-\\$slate-a7)",
    "variable": "var(--colors-\\$slate-a7)"
  },
  "colors.$slateA8": {
    "value": "var(--colors-\\$slate-a8)",
    "variable": "var(--colors-\\$slate-a8)"
  },
  "colors.$slateA9": {
    "value": "var(--colors-\\$slate-a9)",
    "variable": "var(--colors-\\$slate-a9)"
  },
  "colors.$slateA10": {
    "value": "var(--colors-\\$slate-a10)",
    "variable": "var(--colors-\\$slate-a10)"
  },
  "colors.$slateA11": {
    "value": "var(--colors-\\$slate-a11)",
    "variable": "var(--colors-\\$slate-a11)"
  },
  "colors.$slateA12": {
    "value": "var(--colors-\\$slate-a12)",
    "variable": "var(--colors-\\$slate-a12)"
  },
  "colors.$sageA1": {
    "value": "var(--colors-\\$sage-a1)",
    "variable": "var(--colors-\\$sage-a1)"
  },
  "colors.$sageA2": {
    "value": "var(--colors-\\$sage-a2)",
    "variable": "var(--colors-\\$sage-a2)"
  },
  "colors.$sageA3": {
    "value": "var(--colors-\\$sage-a3)",
    "variable": "var(--colors-\\$sage-a3)"
  },
  "colors.$sageA4": {
    "value": "var(--colors-\\$sage-a4)",
    "variable": "var(--colors-\\$sage-a4)"
  },
  "colors.$sageA5": {
    "value": "var(--colors-\\$sage-a5)",
    "variable": "var(--colors-\\$sage-a5)"
  },
  "colors.$sageA6": {
    "value": "var(--colors-\\$sage-a6)",
    "variable": "var(--colors-\\$sage-a6)"
  },
  "colors.$sageA7": {
    "value": "var(--colors-\\$sage-a7)",
    "variable": "var(--colors-\\$sage-a7)"
  },
  "colors.$sageA8": {
    "value": "var(--colors-\\$sage-a8)",
    "variable": "var(--colors-\\$sage-a8)"
  },
  "colors.$sageA9": {
    "value": "var(--colors-\\$sage-a9)",
    "variable": "var(--colors-\\$sage-a9)"
  },
  "colors.$sageA10": {
    "value": "var(--colors-\\$sage-a10)",
    "variable": "var(--colors-\\$sage-a10)"
  },
  "colors.$sageA11": {
    "value": "var(--colors-\\$sage-a11)",
    "variable": "var(--colors-\\$sage-a11)"
  },
  "colors.$sageA12": {
    "value": "var(--colors-\\$sage-a12)",
    "variable": "var(--colors-\\$sage-a12)"
  },
  "colors.$oliveA1": {
    "value": "var(--colors-\\$olive-a1)",
    "variable": "var(--colors-\\$olive-a1)"
  },
  "colors.$oliveA2": {
    "value": "var(--colors-\\$olive-a2)",
    "variable": "var(--colors-\\$olive-a2)"
  },
  "colors.$oliveA3": {
    "value": "var(--colors-\\$olive-a3)",
    "variable": "var(--colors-\\$olive-a3)"
  },
  "colors.$oliveA4": {
    "value": "var(--colors-\\$olive-a4)",
    "variable": "var(--colors-\\$olive-a4)"
  },
  "colors.$oliveA5": {
    "value": "var(--colors-\\$olive-a5)",
    "variable": "var(--colors-\\$olive-a5)"
  },
  "colors.$oliveA6": {
    "value": "var(--colors-\\$olive-a6)",
    "variable": "var(--colors-\\$olive-a6)"
  },
  "colors.$oliveA7": {
    "value": "var(--colors-\\$olive-a7)",
    "variable": "var(--colors-\\$olive-a7)"
  },
  "colors.$oliveA8": {
    "value": "var(--colors-\\$olive-a8)",
    "variable": "var(--colors-\\$olive-a8)"
  },
  "colors.$oliveA9": {
    "value": "var(--colors-\\$olive-a9)",
    "variable": "var(--colors-\\$olive-a9)"
  },
  "colors.$oliveA10": {
    "value": "var(--colors-\\$olive-a10)",
    "variable": "var(--colors-\\$olive-a10)"
  },
  "colors.$oliveA11": {
    "value": "var(--colors-\\$olive-a11)",
    "variable": "var(--colors-\\$olive-a11)"
  },
  "colors.$oliveA12": {
    "value": "var(--colors-\\$olive-a12)",
    "variable": "var(--colors-\\$olive-a12)"
  },
  "colors.$sandA1": {
    "value": "var(--colors-\\$sand-a1)",
    "variable": "var(--colors-\\$sand-a1)"
  },
  "colors.$sandA2": {
    "value": "var(--colors-\\$sand-a2)",
    "variable": "var(--colors-\\$sand-a2)"
  },
  "colors.$sandA3": {
    "value": "var(--colors-\\$sand-a3)",
    "variable": "var(--colors-\\$sand-a3)"
  },
  "colors.$sandA4": {
    "value": "var(--colors-\\$sand-a4)",
    "variable": "var(--colors-\\$sand-a4)"
  },
  "colors.$sandA5": {
    "value": "var(--colors-\\$sand-a5)",
    "variable": "var(--colors-\\$sand-a5)"
  },
  "colors.$sandA6": {
    "value": "var(--colors-\\$sand-a6)",
    "variable": "var(--colors-\\$sand-a6)"
  },
  "colors.$sandA7": {
    "value": "var(--colors-\\$sand-a7)",
    "variable": "var(--colors-\\$sand-a7)"
  },
  "colors.$sandA8": {
    "value": "var(--colors-\\$sand-a8)",
    "variable": "var(--colors-\\$sand-a8)"
  },
  "colors.$sandA9": {
    "value": "var(--colors-\\$sand-a9)",
    "variable": "var(--colors-\\$sand-a9)"
  },
  "colors.$sandA10": {
    "value": "var(--colors-\\$sand-a10)",
    "variable": "var(--colors-\\$sand-a10)"
  },
  "colors.$sandA11": {
    "value": "var(--colors-\\$sand-a11)",
    "variable": "var(--colors-\\$sand-a11)"
  },
  "colors.$sandA12": {
    "value": "var(--colors-\\$sand-a12)",
    "variable": "var(--colors-\\$sand-a12)"
  },
  "colors.$violetA1": {
    "value": "var(--colors-\\$violet-a1)",
    "variable": "var(--colors-\\$violet-a1)"
  },
  "colors.$violetA2": {
    "value": "var(--colors-\\$violet-a2)",
    "variable": "var(--colors-\\$violet-a2)"
  },
  "colors.$violetA3": {
    "value": "var(--colors-\\$violet-a3)",
    "variable": "var(--colors-\\$violet-a3)"
  },
  "colors.$violetA4": {
    "value": "var(--colors-\\$violet-a4)",
    "variable": "var(--colors-\\$violet-a4)"
  },
  "colors.$violetA5": {
    "value": "var(--colors-\\$violet-a5)",
    "variable": "var(--colors-\\$violet-a5)"
  },
  "colors.$violetA6": {
    "value": "var(--colors-\\$violet-a6)",
    "variable": "var(--colors-\\$violet-a6)"
  },
  "colors.$violetA7": {
    "value": "var(--colors-\\$violet-a7)",
    "variable": "var(--colors-\\$violet-a7)"
  },
  "colors.$violetA8": {
    "value": "var(--colors-\\$violet-a8)",
    "variable": "var(--colors-\\$violet-a8)"
  },
  "colors.$violetA9": {
    "value": "var(--colors-\\$violet-a9)",
    "variable": "var(--colors-\\$violet-a9)"
  },
  "colors.$violetA10": {
    "value": "var(--colors-\\$violet-a10)",
    "variable": "var(--colors-\\$violet-a10)"
  },
  "colors.$violetA11": {
    "value": "var(--colors-\\$violet-a11)",
    "variable": "var(--colors-\\$violet-a11)"
  },
  "colors.$violetA12": {
    "value": "var(--colors-\\$violet-a12)",
    "variable": "var(--colors-\\$violet-a12)"
  },
  "colors.$canvas": {
    "value": "var(--colors-\\$canvas)",
    "variable": "var(--colors-\\$canvas)"
  },
  "colors.$shadowLight": {
    "value": "var(--colors-\\$shadow-light)",
    "variable": "var(--colors-\\$shadow-light)"
  },
  "colors.$shadowDark": {
    "value": "var(--colors-\\$shadow-dark)",
    "variable": "var(--colors-\\$shadow-dark)"
  },
  "colors.$overlayB1": {
    "value": "var(--colors-\\$overlay-b1)",
    "variable": "var(--colors-\\$overlay-b1)"
  },
  "colors.$overlayB2": {
    "value": "var(--colors-\\$overlay-b2)",
    "variable": "var(--colors-\\$overlay-b2)"
  },
  "colors.$overlayB3": {
    "value": "var(--colors-\\$overlay-b3)",
    "variable": "var(--colors-\\$overlay-b3)"
  },
  "colors.$overlayB4": {
    "value": "var(--colors-\\$overlay-b4)",
    "variable": "var(--colors-\\$overlay-b4)"
  },
  "colors.$overlayB5": {
    "value": "var(--colors-\\$overlay-b5)",
    "variable": "var(--colors-\\$overlay-b5)"
  },
  "colors.$overlayB6": {
    "value": "var(--colors-\\$overlay-b6)",
    "variable": "var(--colors-\\$overlay-b6)"
  },
  "colors.$overlayB7": {
    "value": "var(--colors-\\$overlay-b7)",
    "variable": "var(--colors-\\$overlay-b7)"
  },
  "colors.$overlayB8": {
    "value": "var(--colors-\\$overlay-b8)",
    "variable": "var(--colors-\\$overlay-b8)"
  },
  "colors.$overlayB9": {
    "value": "var(--colors-\\$overlay-b9)",
    "variable": "var(--colors-\\$overlay-b9)"
  },
  "colors.$overlayB10": {
    "value": "var(--colors-\\$overlay-b10)",
    "variable": "var(--colors-\\$overlay-b10)"
  },
  "colors.$overlayB11": {
    "value": "var(--colors-\\$overlay-b11)",
    "variable": "var(--colors-\\$overlay-b11)"
  },
  "colors.$overlayB12": {
    "value": "var(--colors-\\$overlay-b12)",
    "variable": "var(--colors-\\$overlay-b12)"
  },
  "colors.$primary1": {
    "value": "var(--colors-\\$primary1)",
    "variable": "var(--colors-\\$primary1)"
  },
  "colors.$primary2": {
    "value": "var(--colors-\\$primary2)",
    "variable": "var(--colors-\\$primary2)"
  },
  "colors.$primary3": {
    "value": "var(--colors-\\$primary3)",
    "variable": "var(--colors-\\$primary3)"
  },
  "colors.$primary4": {
    "value": "var(--colors-\\$primary4)",
    "variable": "var(--colors-\\$primary4)"
  },
  "colors.$primary5": {
    "value": "var(--colors-\\$primary5)",
    "variable": "var(--colors-\\$primary5)"
  },
  "colors.$primary6": {
    "value": "var(--colors-\\$primary6)",
    "variable": "var(--colors-\\$primary6)"
  },
  "colors.$primary7": {
    "value": "var(--colors-\\$primary7)",
    "variable": "var(--colors-\\$primary7)"
  },
  "colors.$primary8": {
    "value": "var(--colors-\\$primary8)",
    "variable": "var(--colors-\\$primary8)"
  },
  "colors.$primary9": {
    "value": "var(--colors-\\$primary9)",
    "variable": "var(--colors-\\$primary9)"
  },
  "colors.$primary10": {
    "value": "var(--colors-\\$primary10)",
    "variable": "var(--colors-\\$primary10)"
  },
  "colors.$primary11": {
    "value": "var(--colors-\\$primary11)",
    "variable": "var(--colors-\\$primary11)"
  },
  "colors.$primary12": {
    "value": "var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$primary12)"
  },
  "colors.$primaryA1": {
    "value": "var(--colors-\\$primary-a1)",
    "variable": "var(--colors-\\$primary-a1)"
  },
  "colors.$primaryA2": {
    "value": "var(--colors-\\$primary-a2)",
    "variable": "var(--colors-\\$primary-a2)"
  },
  "colors.$primaryA3": {
    "value": "var(--colors-\\$primary-a3)",
    "variable": "var(--colors-\\$primary-a3)"
  },
  "colors.$primaryA4": {
    "value": "var(--colors-\\$primary-a4)",
    "variable": "var(--colors-\\$primary-a4)"
  },
  "colors.$primaryA5": {
    "value": "var(--colors-\\$primary-a5)",
    "variable": "var(--colors-\\$primary-a5)"
  },
  "colors.$primaryA6": {
    "value": "var(--colors-\\$primary-a6)",
    "variable": "var(--colors-\\$primary-a6)"
  },
  "colors.$primaryA7": {
    "value": "var(--colors-\\$primary-a7)",
    "variable": "var(--colors-\\$primary-a7)"
  },
  "colors.$primaryA8": {
    "value": "var(--colors-\\$primary-a8)",
    "variable": "var(--colors-\\$primary-a8)"
  },
  "colors.$primaryA9": {
    "value": "var(--colors-\\$primary-a9)",
    "variable": "var(--colors-\\$primary-a9)"
  },
  "colors.$primaryA10": {
    "value": "var(--colors-\\$primary-a10)",
    "variable": "var(--colors-\\$primary-a10)"
  },
  "colors.$primaryA11": {
    "value": "var(--colors-\\$primary-a11)",
    "variable": "var(--colors-\\$primary-a11)"
  },
  "colors.$primaryA12": {
    "value": "var(--colors-\\$primary-a12)",
    "variable": "var(--colors-\\$primary-a12)"
  },
  "colors.$secondary1": {
    "value": "var(--colors-\\$secondary1)",
    "variable": "var(--colors-\\$secondary1)"
  },
  "colors.$secondary2": {
    "value": "var(--colors-\\$secondary2)",
    "variable": "var(--colors-\\$secondary2)"
  },
  "colors.$secondary3": {
    "value": "var(--colors-\\$secondary3)",
    "variable": "var(--colors-\\$secondary3)"
  },
  "colors.$secondary4": {
    "value": "var(--colors-\\$secondary4)",
    "variable": "var(--colors-\\$secondary4)"
  },
  "colors.$secondary5": {
    "value": "var(--colors-\\$secondary5)",
    "variable": "var(--colors-\\$secondary5)"
  },
  "colors.$secondary6": {
    "value": "var(--colors-\\$secondary6)",
    "variable": "var(--colors-\\$secondary6)"
  },
  "colors.$secondary7": {
    "value": "var(--colors-\\$secondary7)",
    "variable": "var(--colors-\\$secondary7)"
  },
  "colors.$secondary8": {
    "value": "var(--colors-\\$secondary8)",
    "variable": "var(--colors-\\$secondary8)"
  },
  "colors.$secondary9": {
    "value": "var(--colors-\\$secondary9)",
    "variable": "var(--colors-\\$secondary9)"
  },
  "colors.$secondary10": {
    "value": "var(--colors-\\$secondary10)",
    "variable": "var(--colors-\\$secondary10)"
  },
  "colors.$secondary11": {
    "value": "var(--colors-\\$secondary11)",
    "variable": "var(--colors-\\$secondary11)"
  },
  "colors.$secondary12": {
    "value": "var(--colors-\\$secondary12)",
    "variable": "var(--colors-\\$secondary12)"
  },
  "colors.$secondaryA1": {
    "value": "var(--colors-\\$secondary-a1)",
    "variable": "var(--colors-\\$secondary-a1)"
  },
  "colors.$secondaryA2": {
    "value": "var(--colors-\\$secondary-a2)",
    "variable": "var(--colors-\\$secondary-a2)"
  },
  "colors.$secondaryA3": {
    "value": "var(--colors-\\$secondary-a3)",
    "variable": "var(--colors-\\$secondary-a3)"
  },
  "colors.$secondaryA4": {
    "value": "var(--colors-\\$secondary-a4)",
    "variable": "var(--colors-\\$secondary-a4)"
  },
  "colors.$secondaryA5": {
    "value": "var(--colors-\\$secondary-a5)",
    "variable": "var(--colors-\\$secondary-a5)"
  },
  "colors.$secondaryA6": {
    "value": "var(--colors-\\$secondary-a6)",
    "variable": "var(--colors-\\$secondary-a6)"
  },
  "colors.$secondaryA7": {
    "value": "var(--colors-\\$secondary-a7)",
    "variable": "var(--colors-\\$secondary-a7)"
  },
  "colors.$secondaryA8": {
    "value": "var(--colors-\\$secondary-a8)",
    "variable": "var(--colors-\\$secondary-a8)"
  },
  "colors.$secondaryA9": {
    "value": "var(--colors-\\$secondary-a9)",
    "variable": "var(--colors-\\$secondary-a9)"
  },
  "colors.$secondaryA10": {
    "value": "var(--colors-\\$secondary-a10)",
    "variable": "var(--colors-\\$secondary-a10)"
  },
  "colors.$secondaryA11": {
    "value": "var(--colors-\\$secondary-a11)",
    "variable": "var(--colors-\\$secondary-a11)"
  },
  "colors.$secondaryA12": {
    "value": "var(--colors-\\$secondary-a12)",
    "variable": "var(--colors-\\$secondary-a12)"
  },
  "colors.$accent1": {
    "value": "var(--colors-\\$accent1)",
    "variable": "var(--colors-\\$accent1)"
  },
  "colors.$accent2": {
    "value": "var(--colors-\\$accent2)",
    "variable": "var(--colors-\\$accent2)"
  },
  "colors.$accent3": {
    "value": "var(--colors-\\$accent3)",
    "variable": "var(--colors-\\$accent3)"
  },
  "colors.$accent4": {
    "value": "var(--colors-\\$accent4)",
    "variable": "var(--colors-\\$accent4)"
  },
  "colors.$accent5": {
    "value": "var(--colors-\\$accent5)",
    "variable": "var(--colors-\\$accent5)"
  },
  "colors.$accent6": {
    "value": "var(--colors-\\$accent6)",
    "variable": "var(--colors-\\$accent6)"
  },
  "colors.$accent7": {
    "value": "var(--colors-\\$accent7)",
    "variable": "var(--colors-\\$accent7)"
  },
  "colors.$accent8": {
    "value": "var(--colors-\\$accent8)",
    "variable": "var(--colors-\\$accent8)"
  },
  "colors.$accent9": {
    "value": "var(--colors-\\$accent9)",
    "variable": "var(--colors-\\$accent9)"
  },
  "colors.$accent10": {
    "value": "var(--colors-\\$accent10)",
    "variable": "var(--colors-\\$accent10)"
  },
  "colors.$accent11": {
    "value": "var(--colors-\\$accent11)",
    "variable": "var(--colors-\\$accent11)"
  },
  "colors.$accent12": {
    "value": "var(--colors-\\$accent12)",
    "variable": "var(--colors-\\$accent12)"
  },
  "colors.$accentA1": {
    "value": "var(--colors-\\$accent-a1)",
    "variable": "var(--colors-\\$accent-a1)"
  },
  "colors.$accentA2": {
    "value": "var(--colors-\\$accent-a2)",
    "variable": "var(--colors-\\$accent-a2)"
  },
  "colors.$accentA3": {
    "value": "var(--colors-\\$accent-a3)",
    "variable": "var(--colors-\\$accent-a3)"
  },
  "colors.$accentA4": {
    "value": "var(--colors-\\$accent-a4)",
    "variable": "var(--colors-\\$accent-a4)"
  },
  "colors.$accentA5": {
    "value": "var(--colors-\\$accent-a5)",
    "variable": "var(--colors-\\$accent-a5)"
  },
  "colors.$accentA6": {
    "value": "var(--colors-\\$accent-a6)",
    "variable": "var(--colors-\\$accent-a6)"
  },
  "colors.$accentA7": {
    "value": "var(--colors-\\$accent-a7)",
    "variable": "var(--colors-\\$accent-a7)"
  },
  "colors.$accentA8": {
    "value": "var(--colors-\\$accent-a8)",
    "variable": "var(--colors-\\$accent-a8)"
  },
  "colors.$accentA9": {
    "value": "var(--colors-\\$accent-a9)",
    "variable": "var(--colors-\\$accent-a9)"
  },
  "colors.$accentA10": {
    "value": "var(--colors-\\$accent-a10)",
    "variable": "var(--colors-\\$accent-a10)"
  },
  "colors.$accentA11": {
    "value": "var(--colors-\\$accent-a11)",
    "variable": "var(--colors-\\$accent-a11)"
  },
  "colors.$accentA12": {
    "value": "var(--colors-\\$accent-a12)",
    "variable": "var(--colors-\\$accent-a12)"
  },
  "colors.$error1": {
    "value": "var(--colors-\\$error1)",
    "variable": "var(--colors-\\$error1)"
  },
  "colors.$error2": {
    "value": "var(--colors-\\$error2)",
    "variable": "var(--colors-\\$error2)"
  },
  "colors.$error3": {
    "value": "var(--colors-\\$error3)",
    "variable": "var(--colors-\\$error3)"
  },
  "colors.$error4": {
    "value": "var(--colors-\\$error4)",
    "variable": "var(--colors-\\$error4)"
  },
  "colors.$error5": {
    "value": "var(--colors-\\$error5)",
    "variable": "var(--colors-\\$error5)"
  },
  "colors.$error6": {
    "value": "var(--colors-\\$error6)",
    "variable": "var(--colors-\\$error6)"
  },
  "colors.$error7": {
    "value": "var(--colors-\\$error7)",
    "variable": "var(--colors-\\$error7)"
  },
  "colors.$error8": {
    "value": "var(--colors-\\$error8)",
    "variable": "var(--colors-\\$error8)"
  },
  "colors.$error9": {
    "value": "var(--colors-\\$error9)",
    "variable": "var(--colors-\\$error9)"
  },
  "colors.$error10": {
    "value": "var(--colors-\\$error10)",
    "variable": "var(--colors-\\$error10)"
  },
  "colors.$error11": {
    "value": "var(--colors-\\$error11)",
    "variable": "var(--colors-\\$error11)"
  },
  "colors.$error12": {
    "value": "var(--colors-\\$error12)",
    "variable": "var(--colors-\\$error12)"
  },
  "colors.$errorA1": {
    "value": "var(--colors-\\$error-a1)",
    "variable": "var(--colors-\\$error-a1)"
  },
  "colors.$errorA2": {
    "value": "var(--colors-\\$error-a2)",
    "variable": "var(--colors-\\$error-a2)"
  },
  "colors.$errorA3": {
    "value": "var(--colors-\\$error-a3)",
    "variable": "var(--colors-\\$error-a3)"
  },
  "colors.$errorA4": {
    "value": "var(--colors-\\$error-a4)",
    "variable": "var(--colors-\\$error-a4)"
  },
  "colors.$errorA5": {
    "value": "var(--colors-\\$error-a5)",
    "variable": "var(--colors-\\$error-a5)"
  },
  "colors.$errorA6": {
    "value": "var(--colors-\\$error-a6)",
    "variable": "var(--colors-\\$error-a6)"
  },
  "colors.$errorA7": {
    "value": "var(--colors-\\$error-a7)",
    "variable": "var(--colors-\\$error-a7)"
  },
  "colors.$errorA8": {
    "value": "var(--colors-\\$error-a8)",
    "variable": "var(--colors-\\$error-a8)"
  },
  "colors.$errorA9": {
    "value": "var(--colors-\\$error-a9)",
    "variable": "var(--colors-\\$error-a9)"
  },
  "colors.$errorA10": {
    "value": "var(--colors-\\$error-a10)",
    "variable": "var(--colors-\\$error-a10)"
  },
  "colors.$errorA11": {
    "value": "var(--colors-\\$error-a11)",
    "variable": "var(--colors-\\$error-a11)"
  },
  "colors.$errorA12": {
    "value": "var(--colors-\\$error-a12)",
    "variable": "var(--colors-\\$error-a12)"
  },
  "colors.$success1": {
    "value": "var(--colors-\\$success1)",
    "variable": "var(--colors-\\$success1)"
  },
  "colors.$success2": {
    "value": "var(--colors-\\$success2)",
    "variable": "var(--colors-\\$success2)"
  },
  "colors.$success3": {
    "value": "var(--colors-\\$success3)",
    "variable": "var(--colors-\\$success3)"
  },
  "colors.$success4": {
    "value": "var(--colors-\\$success4)",
    "variable": "var(--colors-\\$success4)"
  },
  "colors.$success5": {
    "value": "var(--colors-\\$success5)",
    "variable": "var(--colors-\\$success5)"
  },
  "colors.$success6": {
    "value": "var(--colors-\\$success6)",
    "variable": "var(--colors-\\$success6)"
  },
  "colors.$success7": {
    "value": "var(--colors-\\$success7)",
    "variable": "var(--colors-\\$success7)"
  },
  "colors.$success8": {
    "value": "var(--colors-\\$success8)",
    "variable": "var(--colors-\\$success8)"
  },
  "colors.$success9": {
    "value": "var(--colors-\\$success9)",
    "variable": "var(--colors-\\$success9)"
  },
  "colors.$success10": {
    "value": "var(--colors-\\$success10)",
    "variable": "var(--colors-\\$success10)"
  },
  "colors.$success11": {
    "value": "var(--colors-\\$success11)",
    "variable": "var(--colors-\\$success11)"
  },
  "colors.$success12": {
    "value": "var(--colors-\\$success12)",
    "variable": "var(--colors-\\$success12)"
  },
  "colors.$successA1": {
    "value": "var(--colors-\\$success-a1)",
    "variable": "var(--colors-\\$success-a1)"
  },
  "colors.$successA2": {
    "value": "var(--colors-\\$success-a2)",
    "variable": "var(--colors-\\$success-a2)"
  },
  "colors.$successA3": {
    "value": "var(--colors-\\$success-a3)",
    "variable": "var(--colors-\\$success-a3)"
  },
  "colors.$successA4": {
    "value": "var(--colors-\\$success-a4)",
    "variable": "var(--colors-\\$success-a4)"
  },
  "colors.$successA5": {
    "value": "var(--colors-\\$success-a5)",
    "variable": "var(--colors-\\$success-a5)"
  },
  "colors.$successA6": {
    "value": "var(--colors-\\$success-a6)",
    "variable": "var(--colors-\\$success-a6)"
  },
  "colors.$successA7": {
    "value": "var(--colors-\\$success-a7)",
    "variable": "var(--colors-\\$success-a7)"
  },
  "colors.$successA8": {
    "value": "var(--colors-\\$success-a8)",
    "variable": "var(--colors-\\$success-a8)"
  },
  "colors.$successA9": {
    "value": "var(--colors-\\$success-a9)",
    "variable": "var(--colors-\\$success-a9)"
  },
  "colors.$successA10": {
    "value": "var(--colors-\\$success-a10)",
    "variable": "var(--colors-\\$success-a10)"
  },
  "colors.$successA11": {
    "value": "var(--colors-\\$success-a11)",
    "variable": "var(--colors-\\$success-a11)"
  },
  "colors.$successA12": {
    "value": "var(--colors-\\$success-a12)",
    "variable": "var(--colors-\\$success-a12)"
  },
  "colors.$info1": {
    "value": "var(--colors-\\$info1)",
    "variable": "var(--colors-\\$info1)"
  },
  "colors.$info2": {
    "value": "var(--colors-\\$info2)",
    "variable": "var(--colors-\\$info2)"
  },
  "colors.$info3": {
    "value": "var(--colors-\\$info3)",
    "variable": "var(--colors-\\$info3)"
  },
  "colors.$info4": {
    "value": "var(--colors-\\$info4)",
    "variable": "var(--colors-\\$info4)"
  },
  "colors.$info5": {
    "value": "var(--colors-\\$info5)",
    "variable": "var(--colors-\\$info5)"
  },
  "colors.$info6": {
    "value": "var(--colors-\\$info6)",
    "variable": "var(--colors-\\$info6)"
  },
  "colors.$info7": {
    "value": "var(--colors-\\$info7)",
    "variable": "var(--colors-\\$info7)"
  },
  "colors.$info8": {
    "value": "var(--colors-\\$info8)",
    "variable": "var(--colors-\\$info8)"
  },
  "colors.$info9": {
    "value": "var(--colors-\\$info9)",
    "variable": "var(--colors-\\$info9)"
  },
  "colors.$info10": {
    "value": "var(--colors-\\$info10)",
    "variable": "var(--colors-\\$info10)"
  },
  "colors.$info11": {
    "value": "var(--colors-\\$info11)",
    "variable": "var(--colors-\\$info11)"
  },
  "colors.$info12": {
    "value": "var(--colors-\\$info12)",
    "variable": "var(--colors-\\$info12)"
  },
  "colors.$infoA1": {
    "value": "var(--colors-\\$info-a1)",
    "variable": "var(--colors-\\$info-a1)"
  },
  "colors.$infoA2": {
    "value": "var(--colors-\\$info-a2)",
    "variable": "var(--colors-\\$info-a2)"
  },
  "colors.$infoA3": {
    "value": "var(--colors-\\$info-a3)",
    "variable": "var(--colors-\\$info-a3)"
  },
  "colors.$infoA4": {
    "value": "var(--colors-\\$info-a4)",
    "variable": "var(--colors-\\$info-a4)"
  },
  "colors.$infoA5": {
    "value": "var(--colors-\\$info-a5)",
    "variable": "var(--colors-\\$info-a5)"
  },
  "colors.$infoA6": {
    "value": "var(--colors-\\$info-a6)",
    "variable": "var(--colors-\\$info-a6)"
  },
  "colors.$infoA7": {
    "value": "var(--colors-\\$info-a7)",
    "variable": "var(--colors-\\$info-a7)"
  },
  "colors.$infoA8": {
    "value": "var(--colors-\\$info-a8)",
    "variable": "var(--colors-\\$info-a8)"
  },
  "colors.$infoA9": {
    "value": "var(--colors-\\$info-a9)",
    "variable": "var(--colors-\\$info-a9)"
  },
  "colors.$infoA10": {
    "value": "var(--colors-\\$info-a10)",
    "variable": "var(--colors-\\$info-a10)"
  },
  "colors.$infoA11": {
    "value": "var(--colors-\\$info-a11)",
    "variable": "var(--colors-\\$info-a11)"
  },
  "colors.$infoA12": {
    "value": "var(--colors-\\$info-a12)",
    "variable": "var(--colors-\\$info-a12)"
  },
  "colors.$warning1": {
    "value": "var(--colors-\\$warning1)",
    "variable": "var(--colors-\\$warning1)"
  },
  "colors.$warning2": {
    "value": "var(--colors-\\$warning2)",
    "variable": "var(--colors-\\$warning2)"
  },
  "colors.$warning3": {
    "value": "var(--colors-\\$warning3)",
    "variable": "var(--colors-\\$warning3)"
  },
  "colors.$warning4": {
    "value": "var(--colors-\\$warning4)",
    "variable": "var(--colors-\\$warning4)"
  },
  "colors.$warning5": {
    "value": "var(--colors-\\$warning5)",
    "variable": "var(--colors-\\$warning5)"
  },
  "colors.$warning6": {
    "value": "var(--colors-\\$warning6)",
    "variable": "var(--colors-\\$warning6)"
  },
  "colors.$warning7": {
    "value": "var(--colors-\\$warning7)",
    "variable": "var(--colors-\\$warning7)"
  },
  "colors.$warning8": {
    "value": "var(--colors-\\$warning8)",
    "variable": "var(--colors-\\$warning8)"
  },
  "colors.$warning9": {
    "value": "var(--colors-\\$warning9)",
    "variable": "var(--colors-\\$warning9)"
  },
  "colors.$warning10": {
    "value": "var(--colors-\\$warning10)",
    "variable": "var(--colors-\\$warning10)"
  },
  "colors.$warning11": {
    "value": "var(--colors-\\$warning11)",
    "variable": "var(--colors-\\$warning11)"
  },
  "colors.$warning12": {
    "value": "var(--colors-\\$warning12)",
    "variable": "var(--colors-\\$warning12)"
  },
  "colors.$warningA1": {
    "value": "var(--colors-\\$warning-a1)",
    "variable": "var(--colors-\\$warning-a1)"
  },
  "colors.$warningA2": {
    "value": "var(--colors-\\$warning-a2)",
    "variable": "var(--colors-\\$warning-a2)"
  },
  "colors.$warningA3": {
    "value": "var(--colors-\\$warning-a3)",
    "variable": "var(--colors-\\$warning-a3)"
  },
  "colors.$warningA4": {
    "value": "var(--colors-\\$warning-a4)",
    "variable": "var(--colors-\\$warning-a4)"
  },
  "colors.$warningA5": {
    "value": "var(--colors-\\$warning-a5)",
    "variable": "var(--colors-\\$warning-a5)"
  },
  "colors.$warningA6": {
    "value": "var(--colors-\\$warning-a6)",
    "variable": "var(--colors-\\$warning-a6)"
  },
  "colors.$warningA7": {
    "value": "var(--colors-\\$warning-a7)",
    "variable": "var(--colors-\\$warning-a7)"
  },
  "colors.$warningA8": {
    "value": "var(--colors-\\$warning-a8)",
    "variable": "var(--colors-\\$warning-a8)"
  },
  "colors.$warningA9": {
    "value": "var(--colors-\\$warning-a9)",
    "variable": "var(--colors-\\$warning-a9)"
  },
  "colors.$warningA10": {
    "value": "var(--colors-\\$warning-a10)",
    "variable": "var(--colors-\\$warning-a10)"
  },
  "colors.$warningA11": {
    "value": "var(--colors-\\$warning-a11)",
    "variable": "var(--colors-\\$warning-a11)"
  },
  "colors.$warningA12": {
    "value": "var(--colors-\\$warning-a12)",
    "variable": "var(--colors-\\$warning-a12)"
  },
  "colors.$crimsonA1": {
    "value": "#ff005503",
    "variable": "var(--colors-\\$crimson-a1)"
  },
  "colors.$crimsonA2": {
    "value": "#e0004008",
    "variable": "var(--colors-\\$crimson-a2)"
  },
  "colors.$crimsonA3": {
    "value": "#ff005216",
    "variable": "var(--colors-\\$crimson-a3)"
  },
  "colors.$crimsonA4": {
    "value": "#f8005123",
    "variable": "var(--colors-\\$crimson-a4)"
  },
  "colors.$crimsonA5": {
    "value": "#e5004f31",
    "variable": "var(--colors-\\$crimson-a5)"
  },
  "colors.$crimsonA6": {
    "value": "#d0004b41",
    "variable": "var(--colors-\\$crimson-a6)"
  },
  "colors.$crimsonA7": {
    "value": "#bf004753",
    "variable": "var(--colors-\\$crimson-a7)"
  },
  "colors.$crimsonA8": {
    "value": "#b6004a6c",
    "variable": "var(--colors-\\$crimson-a8)"
  },
  "colors.$crimsonA9": {
    "value": "#e2005bc2",
    "variable": "var(--colors-\\$crimson-a9)"
  },
  "colors.$crimsonA10": {
    "value": "#d70056cb",
    "variable": "var(--colors-\\$crimson-a10)"
  },
  "colors.$crimsonA11": {
    "value": "#c4004fe2",
    "variable": "var(--colors-\\$crimson-a11)"
  },
  "colors.$crimsonA12": {
    "value": "#530026e9",
    "variable": "var(--colors-\\$crimson-a12)"
  },
  "colors.$text": {
    "value": "var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$text)"
  },
  "colors.$outline": {
    "value": "var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$outline)"
  },
  "colors.$outlineHover": {
    "value": "var(--colors-\\$gray12)",
    "variable": "var(--colors-\\$outline-hover)"
  },
  "colors.$unimportant": {
    "value": "var(--colors-\\$gray10)",
    "variable": "var(--colors-\\$unimportant)"
  },
  "colors.$important": {
    "value": "var(--colors-\\$primary11)",
    "variable": "var(--colors-\\$important)"
  },
  "colors.$highlight": {
    "value": "var(--colors-\\$warning9)",
    "variable": "var(--colors-\\$highlight)"
  },
  "fonts.system": {
    "value": "\"Monaco\", \"Lucida Console\", monospace",
    "variable": "var(--fonts-system)"
  },
  "fonts.untitled": {
    "value": "Untitled Sans, -apple-system, system-ui, sans-serif",
    "variable": "var(--fonts-untitled)"
  },
  "fonts.mono": {
    "value": "Söhne Mono, menlo, monospace",
    "variable": "var(--fonts-mono)"
  },
  "borders.$border": {
    "value": "var(--border-widths-\\$medium) solid var(--colors-\\$primary12)",
    "variable": "var(--borders-\\$border)"
  },
  "borders.$borderThin": {
    "value": "var(--border-widths-\\$thin) solid var(--colors-\\$primary12)",
    "variable": "var(--borders-\\$border-thin)"
  },
  "borders.$borderThick": {
    "value": "var(--border-widths-\\$thick) solid var(--colors-\\$primary12)",
    "variable": "var(--borders-\\$border-thick)"
  },
  "borders.$borderFocus": {
    "value": "0 0 0 2px colors.$focus",
    "variable": "var(--borders-\\$border-focus)"
  },
  "spacing.$1": {
    "value": "5px",
    "variable": "var(--spacing-\\$1)"
  },
  "spacing.$2": {
    "value": "10px",
    "variable": "var(--spacing-\\$2)"
  },
  "spacing.$3": {
    "value": "15px",
    "variable": "var(--spacing-\\$3)"
  },
  "spacing.$4": {
    "value": "20px",
    "variable": "var(--spacing-\\$4)"
  },
  "spacing.$5": {
    "value": "25px",
    "variable": "var(--spacing-\\$5)"
  },
  "spacing.$6": {
    "value": "35px",
    "variable": "var(--spacing-\\$6)"
  },
  "spacing.$7": {
    "value": "45px",
    "variable": "var(--spacing-\\$7)"
  },
  "spacing.$8": {
    "value": "65px",
    "variable": "var(--spacing-\\$8)"
  },
  "spacing.$9": {
    "value": "80px",
    "variable": "var(--spacing-\\$9)"
  },
  "sizes.$1": {
    "value": "5px",
    "variable": "var(--sizes-\\$1)"
  },
  "sizes.$2": {
    "value": "10px",
    "variable": "var(--sizes-\\$2)"
  },
  "sizes.$3": {
    "value": "15px",
    "variable": "var(--sizes-\\$3)"
  },
  "sizes.$4": {
    "value": "20px",
    "variable": "var(--sizes-\\$4)"
  },
  "sizes.$5": {
    "value": "25px",
    "variable": "var(--sizes-\\$5)"
  },
  "sizes.$6": {
    "value": "35px",
    "variable": "var(--sizes-\\$6)"
  },
  "sizes.$7": {
    "value": "45px",
    "variable": "var(--sizes-\\$7)"
  },
  "sizes.$8": {
    "value": "65px",
    "variable": "var(--sizes-\\$8)"
  },
  "sizes.$9": {
    "value": "80px",
    "variable": "var(--sizes-\\$9)"
  },
  "sizes.breakpoint-@bp4": {
    "value": "1800px",
    "variable": "var(--sizes-breakpoint-\\@bp4)"
  },
  "sizes.breakpoint-@bp1": {
    "value": "640px",
    "variable": "var(--sizes-breakpoint-\\@bp1)"
  },
  "sizes.breakpoint-@bp2": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-\\@bp2)"
  },
  "sizes.breakpoint-@bp3": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-\\@bp3)"
  },
  "sizes.breakpoint-@bpdashboard": {
    "value": "900px",
    "variable": "var(--sizes-breakpoint-\\@bpdashboard)"
  },
  "sizes.breakpoint-@bpdashboardsmall": {
    "value": "550px",
    "variable": "var(--sizes-breakpoint-\\@bpdashboardsmall)"
  },
  "sizes.breakpoint-@bpweeklist": {
    "value": "1200px",
    "variable": "var(--sizes-breakpoint-\\@bpweeklist)"
  },
  "sizes.breakpoint-@bpdaysched1": {
    "value": "750px",
    "variable": "var(--sizes-breakpoint-\\@bpdaysched1)"
  },
  "sizes.breakpoint-@bpdayschedsmall": {
    "value": "500px",
    "variable": "var(--sizes-breakpoint-\\@bpdayschedsmall)"
  },
  "sizes.breakpoint-@bplist": {
    "value": "1080px",
    "variable": "var(--sizes-breakpoint-\\@bplist)"
  },
  "sizes.breakpoint-@bplistsmall": {
    "value": "650px",
    "variable": "var(--sizes-breakpoint-\\@bplistsmall)"
  },
  "sizes.breakpoint-@bpdaylist": {
    "value": "1000px",
    "variable": "var(--sizes-breakpoint-\\@bpdaylist)"
  },
  "sizes.breakpoint-@bpheaderday": {
    "value": "750px",
    "variable": "var(--sizes-breakpoint-\\@bpheaderday)"
  },
  "sizes.breakpoint-@bpabout": {
    "value": "770px",
    "variable": "var(--sizes-breakpoint-\\@bpabout)"
  },
  "borderWidths.$thin": {
    "value": "1px",
    "variable": "var(--border-widths-\\$thin)"
  },
  "borderWidths.$medium": {
    "value": "2px",
    "variable": "var(--border-widths-\\$medium)"
  },
  "borderWidths.$thick": {
    "value": "3px",
    "variable": "var(--border-widths-\\$thick)"
  },
  "shadows.$borderHiContrast": {
    "value": "0 0 0 calc(1px / var(--scale-x, 1)) var(--colors-\\$overlay12)",
    "variable": "var(--shadows-\\$border-hi-contrast)"
  },
  "shadows.$success": {
    "value": "0px 0px 3px var(--colors-\\$success11)",
    "variable": "var(--shadows-\\$success)"
  },
  "shadows.$focusPrimary": {
    "value": "0 0 0 2px var(--colors-\\$primary7)",
    "variable": "var(--shadows-\\$focus-primary)"
  },
  "shadows.$border": {
    "value": "0 0 0 calc(1px / var(--scale-x, 1)) var(--colors-\\$overlay2)",
    "variable": "var(--shadows-\\$border)"
  },
  "shadows.$common": {
    "value": "0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)",
    "variable": "var(--shadows-\\$common)"
  },
  "shadows.$error": {
    "value": "0px 0px 3px var(--colors-\\$error11)",
    "variable": "var(--shadows-\\$error)"
  },
  "shadows.$focus": {
    "value": "0 0 0 2px var(--colors-\\$hi-contrast)",
    "variable": "var(--shadows-\\$focus)"
  },
  "shadows.$focusBottom": {
    "value": "0 2px 0px 0px var(--colors-\\$primary7)",
    "variable": "var(--shadows-\\$focus-bottom)"
  },
  "shadows.$medium": {
    "value": "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    "variable": "var(--shadows-\\$medium)"
  },
  "shadows.$strong": {
    "value": "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    "variable": "var(--shadows-\\$strong)"
  },
  "fontSizes.$1": {
    "value": "12px",
    "variable": "var(--font-sizes-\\$1)"
  },
  "fontSizes.$2": {
    "value": "13px",
    "variable": "var(--font-sizes-\\$2)"
  },
  "fontSizes.$3": {
    "value": "15px",
    "variable": "var(--font-sizes-\\$3)"
  },
  "fontSizes.$4": {
    "value": "17px",
    "variable": "var(--font-sizes-\\$4)"
  },
  "fontSizes.$5": {
    "value": "19px",
    "variable": "var(--font-sizes-\\$5)"
  },
  "fontSizes.$6": {
    "value": "21px",
    "variable": "var(--font-sizes-\\$6)"
  },
  "fontSizes.$7": {
    "value": "27px",
    "variable": "var(--font-sizes-\\$7)"
  },
  "fontSizes.$8": {
    "value": "35px",
    "variable": "var(--font-sizes-\\$8)"
  },
  "fontSizes.$9": {
    "value": "59px",
    "variable": "var(--font-sizes-\\$9)"
  },
  "zIndex.$1": {
    "value": "100",
    "variable": "var(--z-index-\\$1)"
  },
  "zIndex.$2": {
    "value": "200",
    "variable": "var(--z-index-\\$2)"
  },
  "zIndex.$3": {
    "value": "300",
    "variable": "var(--z-index-\\$3)"
  },
  "zIndex.$4": {
    "value": "400",
    "variable": "var(--z-index-\\$4)"
  },
  "zIndex.$5": {
    "value": "500",
    "variable": "var(--z-index-\\$5)"
  },
  "zIndex.$max": {
    "value": "999",
    "variable": "var(--z-index-\\$max)"
  },
  "radii.$1": {
    "value": "4px",
    "variable": "var(--radii-\\$1)"
  },
  "radii.$2": {
    "value": "6px",
    "variable": "var(--radii-\\$2)"
  },
  "radii.$3": {
    "value": "8px",
    "variable": "var(--radii-\\$3)"
  },
  "radii.$4": {
    "value": "12px",
    "variable": "var(--radii-\\$4)"
  },
  "radii.$round": {
    "value": "50%",
    "variable": "var(--radii-\\$round)"
  },
  "radii.$pill": {
    "value": "9999px",
    "variable": "var(--radii-\\$pill)"
  },
  "radii.display": {
    "value": "20px",
    "variable": "var(--radii-display)"
  },
  "breakpoints.@bp4": {
    "value": "1800px",
    "variable": "var(--breakpoints-\\@bp4)"
  },
  "breakpoints.@bp1": {
    "value": "640px",
    "variable": "var(--breakpoints-\\@bp1)"
  },
  "breakpoints.@bp2": {
    "value": "768px",
    "variable": "var(--breakpoints-\\@bp2)"
  },
  "breakpoints.@bp3": {
    "value": "1024px",
    "variable": "var(--breakpoints-\\@bp3)"
  },
  "breakpoints.@bpdashboard": {
    "value": "900px",
    "variable": "var(--breakpoints-\\@bpdashboard)"
  },
  "breakpoints.@bpdashboardsmall": {
    "value": "550px",
    "variable": "var(--breakpoints-\\@bpdashboardsmall)"
  },
  "breakpoints.@bpweeklist": {
    "value": "1200px",
    "variable": "var(--breakpoints-\\@bpweeklist)"
  },
  "breakpoints.@bpdaysched1": {
    "value": "750px",
    "variable": "var(--breakpoints-\\@bpdaysched1)"
  },
  "breakpoints.@bpdayschedsmall": {
    "value": "500px",
    "variable": "var(--breakpoints-\\@bpdayschedsmall)"
  },
  "breakpoints.@bplist": {
    "value": "1080px",
    "variable": "var(--breakpoints-\\@bplist)"
  },
  "breakpoints.@bplistsmall": {
    "value": "650px",
    "variable": "var(--breakpoints-\\@bplistsmall)"
  },
  "breakpoints.@bpdaylist": {
    "value": "1000px",
    "variable": "var(--breakpoints-\\@bpdaylist)"
  },
  "breakpoints.@bpheaderday": {
    "value": "750px",
    "variable": "var(--breakpoints-\\@bpheaderday)"
  },
  "breakpoints.@bpabout": {
    "value": "770px",
    "variable": "var(--breakpoints-\\@bpabout)"
  },
  "animations.bounce": {
    "value": "bounce 1s infinite",
    "variable": "var(--animations-bounce)"
  },
  "animations.footSteps": {
    "value": "footSteps 2s infinite steps(7)",
    "variable": "var(--animations-foot-steps)"
  },
  "colors.$panel": {
    "value": "var(--colors-\\$lo-contrast)",
    "variable": "var(--colors-\\$panel)"
  },
  "colors.$unimportantDark": {
    "value": "var(--colors-\\$gray11)",
    "variable": "var(--colors-\\$unimportant-dark)"
  },
  "colors.$unimportantCategory": {
    "value": "var(--colors-\\$unimportant-category)",
    "variable": "var(--colors-\\$unimportant-category)"
  },
  "colors.$defaulttxt": {
    "value": "var(--colors-\\$defaulttxt)",
    "variable": "var(--colors-\\$defaulttxt)"
  },
  "colors.$categorybg1": {
    "value": "var(--colors-\\$categorybg1)",
    "variable": "var(--colors-\\$categorybg1)"
  },
  "colors.$categorytxt1": {
    "value": "var(--colors-\\$categorytxt1)",
    "variable": "var(--colors-\\$categorytxt1)"
  },
  "colors.$categorybg2": {
    "value": "var(--colors-\\$categorybg2)",
    "variable": "var(--colors-\\$categorybg2)"
  },
  "colors.$categorytxt2": {
    "value": "var(--colors-\\$categorytxt2)",
    "variable": "var(--colors-\\$categorytxt2)"
  },
  "colors.$categorybg3": {
    "value": "var(--colors-\\$categorybg3)",
    "variable": "var(--colors-\\$categorybg3)"
  },
  "colors.$categorytxt3": {
    "value": "var(--colors-\\$categorytxt3)",
    "variable": "var(--colors-\\$categorytxt3)"
  },
  "colors.$categorybg4": {
    "value": "var(--colors-\\$categorybg4)",
    "variable": "var(--colors-\\$categorybg4)"
  },
  "colors.$categorytxt4": {
    "value": "var(--colors-\\$categorytxt4)",
    "variable": "var(--colors-\\$categorytxt4)"
  },
  "colors.$categorybg5": {
    "value": "var(--colors-\\$categorybg5)",
    "variable": "var(--colors-\\$categorybg5)"
  },
  "colors.$categorytxt8": {
    "value": "var(--colors-\\$categorytxt8)",
    "variable": "var(--colors-\\$categorytxt8)"
  },
  "colors.$categorybg6": {
    "value": "var(--colors-\\$categorybg6)",
    "variable": "var(--colors-\\$categorybg6)"
  },
  "colors.$categorytxt5": {
    "value": "var(--colors-\\$categorytxt5)",
    "variable": "var(--colors-\\$categorytxt5)"
  },
  "colors.$categorybg7": {
    "value": "var(--colors-\\$categorybg7)",
    "variable": "var(--colors-\\$categorybg7)"
  },
  "colors.$categorytxt6": {
    "value": "var(--colors-\\$categorytxt6)",
    "variable": "var(--colors-\\$categorytxt6)"
  },
  "colors.$categorybg8": {
    "value": "var(--colors-\\$categorybg8)",
    "variable": "var(--colors-\\$categorybg8)"
  },
  "colors.$categorytxt7": {
    "value": "var(--colors-\\$categorytxt7)",
    "variable": "var(--colors-\\$categorytxt7)"
  },
  "colors.$border": {
    "value": "var(--border-widths-\\$medium) solid var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$border)"
  },
  "colors.$borderThin": {
    "value": "var(--border-widths-\\$thin) solid var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$border-thin)"
  },
  "colors.$borderThick": {
    "value": "var(--border-widths-\\$thick) solid var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$border-thick)"
  },
  "colors.$borderFocus": {
    "value": "0 0 0 2px colors.$focus",
    "variable": "var(--colors-\\$border-focus)"
  },
  "colors.$monthbg": {
    "value": "var(--colors-\\$monthbg)",
    "variable": "var(--colors-\\$monthbg)"
  },
  "colors.$listbg": {
    "value": "var(--colors-\\$listbg)",
    "variable": "var(--colors-\\$listbg)"
  },
  "colors.$listBgBack": {
    "value": "var(--colors-\\$primary9)",
    "variable": "var(--colors-\\$list-bg-back)"
  },
  "colors.$listBgFront": {
    "value": "var(--colors-\\$primary1)",
    "variable": "var(--colors-\\$list-bg-front)"
  },
  "colors.$taskbg": {
    "value": "var(--colors-\\$taskbg)",
    "variable": "var(--colors-\\$taskbg)"
  },
  "colors.$eventtxt": {
    "value": "var(--colors-\\$eventtxt)",
    "variable": "var(--colors-\\$eventtxt)"
  },
  "colors.$eventbg": {
    "value": "var(--colors-\\$eventbg)",
    "variable": "var(--colors-\\$eventbg)"
  },
  "colors.$eventcardbg": {
    "value": "var(--colors-\\$eventcardbg)",
    "variable": "var(--colors-\\$eventcardbg)"
  },
  "colors.$eventcardtxt": {
    "value": "var(--colors-\\$eventcardtxt)",
    "variable": "var(--colors-\\$eventcardtxt)"
  },
  "colors.$eventcardoutline": {
    "value": "var(--colors-\\$eventcardoutline)",
    "variable": "var(--colors-\\$eventcardoutline)"
  },
  "colors.$sectionHeaderbg": {
    "value": "var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$section-headerbg)"
  },
  "colors.$sectionHeadertxt": {
    "value": "var(--colors-\\$gray1)",
    "variable": "var(--colors-\\$section-headertxt)"
  },
  "colors.$sectionHeaderborder": {
    "value": "var(--colors-\\$primary12)",
    "variable": "var(--colors-\\$section-headerborder)"
  },
  "colors.$timegridbg": {
    "value": "var(--colors-\\$timegridbg)",
    "variable": "var(--colors-\\$timegridbg)"
  },
  "colors.$timegridCellHover": {
    "value": "var(--colors-\\$timegrid-cell-hover)",
    "variable": "var(--colors-\\$timegrid-cell-hover)"
  },
  "colors.$daygridToday": {
    "value": "var(--colors-\\$daygrid-today)",
    "variable": "var(--colors-\\$daygrid-today)"
  },
  "colors.$daygridBg": {
    "value": "var(--colors-\\$daygrid-bg)",
    "variable": "var(--colors-\\$daygrid-bg)"
  },
  "colors.$calviewbg": {
    "value": "var(--colors-\\$calviewbg)",
    "variable": "var(--colors-\\$calviewbg)"
  },
  "colors.$calviewborder": {
    "value": "var(--colors-\\$calviewborder)",
    "variable": "var(--colors-\\$calviewborder)"
  },
  "colors.$calviewcolor": {
    "value": "var(--colors-\\$calviewcolor)",
    "variable": "var(--colors-\\$calviewcolor)"
  },
  "colors.$weekbg": {
    "value": "var(--colors-\\$gray1)",
    "variable": "var(--colors-\\$weekbg)"
  },
  "colors.$weektimebg": {
    "value": "var(--colors-\\$gray1)",
    "variable": "var(--colors-\\$weektimebg)"
  },
  "colors.$weekboxesbg": {
    "value": "var(--colors-\\$weekboxesbg)",
    "variable": "var(--colors-\\$weekboxesbg)"
  },
  "colors.$homebg": {
    "value": "var(--colors-\\$homebg)",
    "variable": "var(--colors-\\$homebg)"
  },
  "sizes.widthOfHourLabels": {
    "value": "3.2rem",
    "variable": "var(--sizes-width-of-hour-labels)"
  },
  "sizes.widthOfHourLabelsSmall": {
    "value": "1.75rem",
    "variable": "var(--sizes-width-of-hour-labels-small)"
  },
  "sizes.HEIGHT_OF_TIMEGRID_CELL_IN_REM": {
    "value": "2rem",
    "variable": "var(--sizes--h-e-i-g-h-t_-o-f_-t-i-m-e-g-r-i-d_-c-e-l-l_-i-n_-r-e-m)"
  },
  "sizes.sheetWidth": {
    "value": "350px",
    "variable": "var(--sizes-sheet-width)"
  },
  "sizes.sheetHeight": {
    "value": "320px",
    "variable": "var(--sizes-sheet-height)"
  },
  "sizes.dragHandleWidth": {
    "value": "8px",
    "variable": "var(--sizes-drag-handle-width)"
  },
  "sizes.widthOfTaskBullet": {
    "value": "22rem",
    "variable": "var(--sizes-width-of-task-bullet)"
  },
  "sizes.heightOfTaskBullet": {
    "value": "3.5rem",
    "variable": "var(--sizes-height-of-task-bullet)"
  },
  "sizes.infoBarWidth": {
    "value": "18rem",
    "variable": "var(--sizes-info-bar-width)"
  },
  "spacing.viewportToastPadding": {
    "value": "25px",
    "variable": "var(--spacing-viewport-toast-padding)"
  },
  "spacing.aboutLeftPadding": {
    "value": "3rem",
    "variable": "var(--spacing-about-left-padding)"
  },
  "spacing.formPadding": {
    "value": "25px",
    "variable": "var(--spacing-form-padding)"
  },
  "spacing.formGap": {
    "value": "20px",
    "variable": "var(--spacing-form-gap)"
  },
  "spacing.taskDisplayPadding": {
    "value": "0.7rem",
    "variable": "var(--spacing-task-display-padding)"
  },
  "shadows.boxShadow": {
    "value": "var(--shadows-\\$border), var(--shadows-\\$common)",
    "variable": "var(--shadows-box-shadow)"
  },
  "shadows.$boxShadow": {
    "value": "var(--shadows-\\$border), var(--shadows-\\$common)",
    "variable": "var(--shadows-\\$box-shadow)"
  },
  "shadows.tiny": {
    "value": "var(--colors-\\$overlay1) 0px 0px 0px 1px",
    "variable": "var(--shadows-tiny)"
  },
  "radii.taskBullet": {
    "value": "10px",
    "variable": "var(--radii-task-bullet)"
  },
  "spacing.-$1": {
    "value": "calc(var(--spacing-\\$1) * -1)",
    "variable": "var(--spacing-\\$1)"
  },
  "spacing.-$2": {
    "value": "calc(var(--spacing-\\$2) * -1)",
    "variable": "var(--spacing-\\$2)"
  },
  "spacing.-$3": {
    "value": "calc(var(--spacing-\\$3) * -1)",
    "variable": "var(--spacing-\\$3)"
  },
  "spacing.-$4": {
    "value": "calc(var(--spacing-\\$4) * -1)",
    "variable": "var(--spacing-\\$4)"
  },
  "spacing.-$5": {
    "value": "calc(var(--spacing-\\$5) * -1)",
    "variable": "var(--spacing-\\$5)"
  },
  "spacing.-$6": {
    "value": "calc(var(--spacing-\\$6) * -1)",
    "variable": "var(--spacing-\\$6)"
  },
  "spacing.-$7": {
    "value": "calc(var(--spacing-\\$7) * -1)",
    "variable": "var(--spacing-\\$7)"
  },
  "spacing.-$8": {
    "value": "calc(var(--spacing-\\$8) * -1)",
    "variable": "var(--spacing-\\$8)"
  },
  "spacing.-$9": {
    "value": "calc(var(--spacing-\\$9) * -1)",
    "variable": "var(--spacing-\\$9)"
  },
  "spacing.-viewportToastPadding": {
    "value": "calc(var(--spacing-viewport-toast-padding) * -1)",
    "variable": "var(--spacing-viewport-toast-padding)"
  },
  "spacing.-aboutLeftPadding": {
    "value": "calc(var(--spacing-about-left-padding) * -1)",
    "variable": "var(--spacing-about-left-padding)"
  },
  "spacing.-formPadding": {
    "value": "calc(var(--spacing-form-padding) * -1)",
    "variable": "var(--spacing-form-padding)"
  },
  "spacing.-formGap": {
    "value": "calc(var(--spacing-form-gap) * -1)",
    "variable": "var(--spacing-form-gap)"
  },
  "spacing.-taskDisplayPadding": {
    "value": "calc(var(--spacing-task-display-padding) * -1)",
    "variable": "var(--spacing-task-display-padding)"
  },
  "colors.$tasktxt": {
    "value": "var(--colors-\\$tasktxt)",
    "variable": "var(--colors-\\$tasktxt)"
  },
  "colors.$tan2v": {
    "value": "var(--colors-\\$tan2v)",
    "variable": "var(--colors-\\$tan2v)"
  },
  "colors.$gray1v": {
    "value": "var(--colors-\\$gray1v)",
    "variable": "var(--colors-\\$gray1v)"
  },
  "colors.$brown1v": {
    "value": "var(--colors-\\$brown1v)",
    "variable": "var(--colors-\\$brown1v)"
  },
  "colors.$brown2v": {
    "value": "var(--colors-\\$brown2v)",
    "variable": "var(--colors-\\$brown2v)"
  },
  "colors.$lightbrownv": {
    "value": "var(--colors-\\$lightbrownv)",
    "variable": "var(--colors-\\$lightbrownv)"
  },
  "colors.$lbrownv": {
    "value": "var(--colors-\\$lbrownv)",
    "variable": "var(--colors-\\$lbrownv)"
  },
  "colors.$tanv": {
    "value": "var(--colors-\\$tanv)",
    "variable": "var(--colors-\\$tanv)"
  },
  "colors.$lightgreen3v": {
    "value": "var(--colors-\\$lightgreen3v)",
    "variable": "var(--colors-\\$lightgreen3v)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar