'use client'
import React from 'react'
import HeatCalendar from './HeatCalendar'
import { Activity } from 'src/types/activity'
import { formatDay } from '@planda/utils'
import { addMonths, startOfWeek } from 'date-fns'
import { MS_PER_DAY } from '@/constants/date'
import { useSWR } from 'src/lib/swr'
// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/calendar

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const ActivityGraph = ({ type, ...props }: { type: 'completed' | 'time'; height?: number; width?: number; title?: string }) => {
    const to = formatDay(Date.now() + MS_PER_DAY)
    const from = formatDay(startOfWeek(addMonths(Date.now(), -4)))
    const { data } = useSWR<Activity[]>(`/api/activity?start=${from}&type=${type === 'completed' ? 'task' : 'event'}`)
    const calendarData = data
        ?.map((x) => ({
            day: x.day,
            value: (type === 'completed' ? x.taskCompletionCount : x.eventHours) || 0,
        }))
        .filter((x) => x.value > 0)
    // how to exclude slept hours + meals?
    // should optional events be excluded? need to do attendance
    return <HeatCalendar data={calendarData} from={from} to={to} {...props} />
}
