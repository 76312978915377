'use client'
import { recurrenceOccursOnDate } from '@/lib/zod'
import { startOfDay } from 'date-fns'
import { HabitEntry, SingleHabit, HabitDot } from './types'
import { nanoid } from 'nanoid'
import { range, uniq } from 'lodash'
import { RequiredK } from '@/types'

export const isUsingTargets = <T extends Pick<SingleHabit, 'targets'>>(habit: T): habit is RequiredK<T, 'targets'> =>
    !!(habit.targets && habit.targets.length > 0 && habit.targets[0].target)

const doesHabitOccurOnDay = (habit: SingleHabit, day: number) => {
    const dayOfWeek = new Date(day).getDay()
    if (isUsingTargets(habit) && habit.targets.some(({ daysOfWeek }) => daysOfWeek.includes(dayOfWeek))) {
        return true
    }
    return habit.recurrence ? recurrenceOccursOnDate(habit.recurrence, day, habit.createdAt) : true
}

export const dayToTarget = (habit: Pick<SingleHabit, 'target' | 'targets'>, day: number) => {
    if (!isUsingTargets(habit)) return habit.target

    const dayOfWeek = new Date(day).getDay()
    const target = habit.targets.find(({ daysOfWeek }) => daysOfWeek.includes(dayOfWeek))
    return target?.target || habit.target
}

export const getDot = (entry: HabitEntry | undefined, habit: SingleHabit, day: number) => {
    const isInvalidOnDay = doesHabitOccurOnDay(habit, day)
    return entry ? entry.dot : day < startOfDay(habit.createdAt).getTime() || isInvalidOnDay ? HabitDot.GRAY : HabitDot.UNSET
}

export const finalColumnDot = (entries: { entry: HabitEntry | undefined; habit: SingleHabit }[], day: number) => {
    const allGood = entries.every(({ entry, habit }) => {
        const dot = getDot(entry, habit, day)
        return dot === HabitDot.GREEN || dot === HabitDot.GRAY
    })

    const allFinished = entries.every(({ entry, habit }) => getDot(entry, habit, day) !== HabitDot.UNSET)

    return allGood ? HabitDot.GREEN : allFinished ? HabitDot.RED : HabitDot.UNSET
}

export const getDefaultHabit = (defaultValues?: Partial<SingleHabit>): SingleHabit => {
    const target = defaultValues?.target && !isUsingTargets(defaultValues) ? defaultValues.target : ''
    return {
        name: '',
        id: nanoid(6),
        target: '',
        targets: [
            {
                target,
                daysOfWeek: range(7),
            },
        ],
        level: 0,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        ...defaultValues,
    }
}

export const habitTargetsToAllDaysOfWeek = (targets: SingleHabit['targets']) => {
    const allDaysOfWeek = uniq(
        targets?.flatMap(({ daysOfWeek }) => {
            return daysOfWeek
        }) || []
    )
    return allDaysOfWeek
}
