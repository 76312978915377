'use client'
import { isToday, startOfDay } from 'date-fns'
import React, { memo } from 'react'
import { css, cx } from 'styled-system/css'
import PawPrints from '@/components/common/loaders/PawPrints'
import { tableSlots } from 'styled-system/recipes'
import HabitHeaders from './HabitHeaders'
import useHabits from './useHabits'
import HabitRow from './HabitRow'

// import { table as tableRecipe } from 'styled-system/recipes'

// 20 green dots in a row means you can up difficulty
// greed or red dot for whether you met target
// set bar as low as possible, so you don't feel aversion
// Remember that
// gradual change is lasting change
// A samari has no goal, only a path

const HabitTracker = ({
    filterOutNotRelevant = false,
    days = 30,
    labelType,
}: {
    filterOutNotRelevant?: boolean
    days?: number
    labelType?: 'icons' | 'short'
}) => {
    const { table, th, tr, thead, tbody, td } = tableSlots({ alignTh: labelType === 'icons' ? 'center' : 'start' })

    const { isLoading, habits, relevantDays, earliestEntryDate, entriesMap } = useHabits({ days, filterOutNotRelevant })

    if (isLoading) return <PawPrints />
    return (
        <table className={cx(table, css({ tableLayout: 'auto', borderCollapse: 'collapse' }))}>
            <thead className={thead}>
                <tr className={tr}>
                    <HabitHeaders habits={habits} labelType={labelType} className={th} />
                </tr>
            </thead>
            <tbody className={tbody}>
                {/* // Today must be at the top, and must be editable */}
                {relevantDays
                    .filter((day) => (earliestEntryDate ? day >= startOfDay(earliestEntryDate).getTime() : isToday(day)))
                    .map((day) => {
                        return <HabitRow habits={habits} td={td} tr={tr} key={day} day={day} entriesMap={entriesMap} />
                    })}
            </tbody>
        </table>
    )
}

export default memo(HabitTracker)
