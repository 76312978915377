import { useMemo } from 'react'
import { endOfDay, startOfDay } from 'date-fns'
import { useGetHabitsOrderQuery, useGetHabitsResQuery } from '@/redux/features/api'
import { MS_PER_DAY, daysInRange } from '@planda/utils'
import { recurrenceOccursInRange } from '@/lib/zod'
import { habitTargetsToAllDaysOfWeek, isUsingTargets } from './utils'

const useHabits = ({ days, filterOutNotRelevant }: { days: number; filterOutNotRelevant: boolean }) => {
    const { relevantDays, start, end } = useMemo(() => {
        const start = startOfDay(Date.now()).getTime() - MS_PER_DAY * (days - 1),
            end = endOfDay(Date.now()).getTime()
        return { start, end, relevantDays: daysInRange(start, end).slice().reverse() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [days])

    const { data, isLoading } = useGetHabitsResQuery({ start, end })
    const { data: habitsOrderData } = useGetHabitsOrderQuery()
    const { entries: entriesMap = {}, earliestEntryDate } = data || {}

    const habits = useMemo(() => {
        const order = habitsOrderData?.order
        const habits =
            data?.habits.filter(({ recurrence, targets }) => {
                if (!filterOutNotRelevant) return true
                if (isUsingTargets({ targets })) {
                    const allDaysOfWeek = habitTargetsToAllDaysOfWeek(targets)
                    return recurrenceOccursInRange({ daysOfWeek: allDaysOfWeek }, start, end)
                }
                return recurrence ? recurrenceOccursInRange(recurrence, start, end) : true
            }) || []
        if (!order) return habits
        return habits.sort((a, b) => {
            return order.indexOf(a.id) - order.indexOf(b.id)
        })
    }, [data, habitsOrderData, start, end, filterOutNotRelevant])

    return {
        isLoading,
        habits,
        entriesMap,
        earliestEntryDate,
        relevantDays,
    }
}

export default useHabits
