import useGlobalForm from '@/hooks/useGlobalForm'
import React, { useCallback } from 'react'
import useRemoveItem from './useRemoveItem'
import { Item } from '@/types'
import { ContextMenuUnit } from '@/components/common'
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons'
import { useUpdatePlannerItemMutation } from '@/redux/features/api'
import { MS_PER_HOUR, MS_PER_DAY, MS_PER_WEEK } from '@planda/utils'
import EventCronParser from 'event-cron-parser'
import { DEFAULT_CRON } from '@/features/forms/constants'

const HIDE_TASK_OPTIONS: Record<string, { ms: number; confirmMsg?: string }> = {
    '1 hour': { ms: MS_PER_HOUR },
    '1 day': { ms: MS_PER_DAY, confirmMsg: 'Sure?' },
    '3 days': { ms: MS_PER_DAY * 3, confirmMsg: '3 days is pretty long...' },
    '1 week': { ms: MS_PER_WEEK, confirmMsg: 'Are you SURE about 1 WHOLE WEEK' },
}

const useItemContextMenu = () => {
    const { openEditItemForm } = useGlobalForm()
    const { remove, exclude } = useRemoveItem()
    const [updateItem] = useUpdatePlannerItemMutation()
    /**
     *
     * @param item
     * @param handleEdit a function that opens a form, or switches tabs in a form
     * @returns
     */
    const createItemContextMenu = useCallback(
        (item: Item, templateInstance?: { dateStart: number }): ContextMenuUnit[] => {
            if (!item.id) return [] // loading or unfinished add item

            const baseMenu = [
                {
                    label: 'Edit',
                    rightSlot: <Pencil1Icon />,
                    onSelect: () => openEditItemForm(item), // TODO should open up a form, or switch tabs if inForm=true
                },
                {
                    label: 'Delete',
                    rightSlot: <TrashIcon />,
                    onSelect: () => remove(item.id),
                },
            ]

            if (item.type === 'templateRecur') {
                return [
                    ...baseMenu,
                    {
                        label: 'Exclude',
                        rightSlot: <TrashIcon />,
                        onSelect: async () => exclude(item.id, templateInstance?.dateStart || item.dateStart),
                    },
                ]
            }

            if (item.type === 'task') {
                return [
                    ...baseMenu,
                    {
                        label: 'Hide',
                        content: Object.keys(HIDE_TASK_OPTIONS).map((desc) => {
                            const option = HIDE_TASK_OPTIONS[desc]

                            return {
                                label: `For ${desc}`,
                                onSelect: () => {
                                    if (option.confirmMsg && !window.confirm(option.confirmMsg)) return
                                    updateItem({ id: item.id, updates: { set: { hideUntil: Date.now() + option.ms } } })
                                },
                            }
                        }),
                    },
                ]
            }

            if (item.type === 'event') {
                // add 'Convert to Recurring Event' option
                return [
                    ...baseMenu,
                    {
                        label: 'Convert to recurring event',
                        rightSlot: <TrashIcon />,
                        onSelect: async () => {
                            const { id, type, dateEnd, ...rest } = item
                            const start = new Date(item.dateStart)
                            const parser = new EventCronParser(DEFAULT_CRON, item.dateStart, undefined)
                            parser.setDaysOfWeek([start.getDay()], 'local')
                            parser.setDuration(item.dateEnd - item.dateStart)
                            openEditItemForm({
                                ...rest,
                                type: 'templateRecur',
                                cron: parser.getCron(),
                            })
                        },
                    },
                ]
            }

            return baseMenu
        },
        [exclude, openEditItemForm, remove, updateItem]
    )

    return {
        createItemContextMenu,
    }
}

export default useItemContextMenu
