'use client'
import { styled } from 'styled-system/jsx'
import { css, cx } from 'styled-system/css'
import { Cross1Icon, DotFilledIcon } from '@radix-ui/react-icons'
import { inputLayoutStyle, InputWrapper } from './Input'
import { forwardRef, ReactNode, useId, useState } from 'react'
import { BareFlexCol } from './commonStyles'
import { RecipeVariantProps } from 'styled-system/types'
import { cva } from 'styled-system/css'

/**
 * For dynamic inputs in list format, options for bullet point or checkbox list (uses checkbox radix)
 */
const BulletIcon = styled(DotFilledIcon, {
    base: {
        size: '22px',
    },
})

const CrossIcon = styled(Cross1Icon, {
    base: {
        color: '$gray8',
        size: '10px',
        '&:hover': {
            color: '$gray10',
            scale: 1.02,
        },
    },
})

const FloatingLabel = styled('label', {
    variants: {
        float: {
            true: {
                fontSize: '0.65em',
                alignItems: 'flex-start',
                top: '-1em',
                marginLeft: 0,
                background: 'inherit',
            },
        },
    },
    base: {
        position: 'absolute',
        flexGeneral: 'row',
        size: 'max-content',
        left: 7,
        top: 'auto',
        color: '$gray10',
        fontSize: '0.85em',
        pointerEvents: 'none',
        marginLeft: -2,
        opacity: 1,
        zIndex: 10,
        animation: 'top 3s ease-out, flex 3s ease-out, margin-left 2s',
    },
})

const listInput = cva({
    base: {
        all: 'unset',
        ...inputLayoutStyle,
        fontSize: '0.9em',
        width: 130,
        padding: 5,
        paddingBlock: 3,
        background: 'transparent',
        borderRadius: 0,
        borderBottom: '$border',
        borderBottomColor: '$gray7',
        '&:hover': { borderBottomColor: '$primary6' },
        '&:focus': { borderBottomColor: '$primary8' },
        '&::placeholder': {
            opacity: 0.6,
        },
    },
    variants: {
        error: {
            true: { borderBottomColor: '$error9' },
        },
    },
})
export const ListInput = styled('input', listInput)

const Flex = styled('div', {
    base: {
        position: 'relative',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        background: 'inherit',
        gap: 10,
    },
})

interface ListInput {
    placeholder?: string
}

export const BulletInputsLineWrapper = ({
    handleDelete,
    children,
    hideBullet,
}: {
    handleDelete?: () => void
    children: ReactNode
    hideBullet?: boolean
}) => {
    return (
        <InputWrapper className={css({ gap: 2 })}>
            {hideBullet ? null : <BulletIcon />}
            <Flex>{children}</Flex>
            {handleDelete && <CrossIcon onClick={handleDelete} />}
        </InputWrapper>
    )
}
BulletInputsLineWrapper.displayName = 'BulletInputsLineWrapper'

type ListInputProps = RecipeVariantProps<typeof listInput> & React.InputHTMLAttributes<HTMLInputElement> & { floatLabel?: boolean; error?: boolean }
export const FloatableInput = forwardRef<HTMLInputElement, ListInputProps>(
    ({ children, floatLabel = false, type = 'text', className, placeholder = '', onChange, error = false, ...props }, forwardedRef) => {
        const id = useId()
        const [hasValue, setHasValue] = useState(!!props.defaultValue || !!props.value)

        return (
            <BareFlexCol>
                {floatLabel && <FloatingLabel float={hasValue}>{placeholder}</FloatingLabel>}
                <input
                    className={cx(listInput({ error }), className)}
                    ref={forwardedRef}
                    id={id}
                    placeholder={floatLabel ? undefined : placeholder}
                    onChange={(e) => {
                        onChange && onChange(e)
                        setHasValue(!!e.target.value)
                    }}
                    type={type}
                    {...props}
                />
                {children}
            </BareFlexCol>
        )
    }
)
FloatableInput.displayName = 'FloatableInput'

type BulletSingleInputProps = RecipeVariantProps<typeof listInput> &
    React.InputHTMLAttributes<HTMLInputElement> & { handleDelete?: () => void; error?: boolean } & ListInputProps
export const BulletSingleInput = forwardRef<HTMLInputElement, BulletSingleInputProps>(({ handleDelete, ...props }, forwardedRef) => {
    return (
        <InputWrapper className={css({ gap: 2 })}>
            <BulletIcon />
            <Flex>
                <FloatableInput {...props} ref={forwardedRef} />
            </Flex>
            {handleDelete && <CrossIcon onClick={handleDelete} />}
        </InputWrapper>
    )
})
BulletSingleInput.displayName = 'BulletSingleInput'

// const InputList = () => {
//     return (
//         <div>InputList</div>
//     )
// }

// export default InputList
