import React, { memo } from 'react'
import { SingleHabit } from './types'
import { css, cx } from 'styled-system/css'
import HabitFormPopover from './HabitFormPopover'
import { iconButton } from 'styled-system/recipes'
import { Pencil1Icon, PlusIcon } from '@radix-ui/react-icons'
import { dayToTarget } from './utils'

const cell = css.raw({ paddingInline: '$2', border: '1px solid {colors.$gray4}' })

const HabitHeaders = ({ habits, className, labelType }: { habits: SingleHabit[]; className?: string; labelType: 'icons' | 'short' | undefined }) => {
    return (
        <>
            <th className={cx(className, css(cell))}>Date</th>
            {habits.map((habit) => {
                return <Habit key={habit.id} habit={habit} labelType={labelType} className={className} />
            })}
            <th className={cx(className, css(cell))}>Overall</th>
            <th className={cx(className, css({ border: '1px solid {colors.$gray4}', position: 'relative' }))}>
                <HabitFormPopover>
                    <button className={iconButton()}>
                        <PlusIcon />
                    </button>
                </HabitFormPopover>
            </th>
        </>
    )
}

export default memo(HabitHeaders)

const Habit = ({ habit, className, labelType }: { habit: SingleHabit; className?: string; labelType: 'icons' | 'short' | undefined }) => {
    const target = dayToTarget(habit, new Date().getDay())

    return (
        <th
            className={cx(
                className,
                css(cell, {
                    position: 'relative',
                    _hover: {
                        '& #icons': { display: 'block' },
                    },
                })
            )}
            align="center"
            key={habit.id + target}
        >
            {/* {habit.name} */}
            <div className={css({ display: 'inline-flex', gap: '$1', justifyContent: 'center' })}>
                {labelType === 'icons' ? (
                    <span>{habit.symbol || habit.name}</span>
                ) : (
                    <>
                        <span>{habit.symbol}</span> <span>{target}</span>
                    </>
                )}
            </div>

            <div id="icons" className={css({ position: 'absolute', display: 'none', top: 0, right: 0, margin: '$1' })}>
                <HabitFormPopover defaultValues={habit}>
                    <button className={iconButton()}>
                        <Pencil1Icon />
                    </button>
                </HabitFormPopover>
            </div>
        </th>
    )
}
