import { addDays, differenceInMilliseconds, roundToNearestMinutes } from 'date-fns'
import { groupBy, keyBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { HEIGHT_OF_TIMEGRID_CELL_IN_REM, MS_PER_HALF_HOUR } from '../../constants'
import { CalendarEvent, NewItemInfo, isRecurringEvent } from '../../types'
import { EventLayout, useLayout } from './useLayout'
import { DraggableData } from 'react-rnd'
import { ParentSet } from '../../hooks/useData'
import EventCronParser from 'event-cron-parser'
import { handleCronDayOfWeekChange } from '../../utils'
import { useCurrentState } from '@/hooks/useCurrentState'
import { remToPx } from '@/hooks/useRemToPx'
import { token } from 'styled-system/tokens'
import { usePutAchievementMutation } from '@/redux/features/api'
import Swal from 'sweetalert2'
type Direction = 'top' | 'bottom' // | 'left' | 'topRight' | 'bottomRight' | 'bottomLeft' | 'topLeft' | 'right'

/**
 * handles Rnd
 * @param events
 * @param firstDay REQUIRES: hours set to 0 (very start of day)
 * @param days number of days/columns in timegrid
 * @param cellHeight height of cell (should probably be a constant, but in rem so need conversion)
 * @returns
 */
export function useRnd(
    events: CalendarEvent[],
    editCalItem: (id: string, updates: { [x: string]: any }) => any,
    eventSet: ParentSet,
    firstDay: number,
    days = 1,
    cellHeight: number,
    handleEventChange: (id: string, newEvent: { dateStart?: number; dateEnd?: number; cron?: string }) => void,
    setIsDragging: (isDragging: boolean) => void,
    {
        handleRemoveItem,
        isDraggingRef,
        handleDoubleClickDate,
    }: { handleRemoveItem?: (id: string) => void; isDraggingRef: any; handleDoubleClickDate?: (day: NewItemInfo) => any }
    // handleDurationChange: (id: string, durationChange: number, newDateEnd: number) => void,
    // handleDateChange: (id: string, newDateStart: number, newDateEnd: number) => void,
) {
    const DEFAULT_CELL_HEIGHT = remToPx(HEIGHT_OF_TIMEGRID_CELL_IN_REM)
    cellHeight = cellHeight || DEFAULT_CELL_HEIGHT
    const { shortLayout, longLayout } = useLayout(events, eventSet, firstDay, days)
    const [cellWidth, setCellWidth, cellWidthRef] = useCurrentState(0) // causes timegrid to rerender
    const [putAchievement] = usePutAchievementMutation()
    // const [gridHeight, setGridHeight] = useState(0) // causing errors max updates error
    const idMap = keyBy(events, (x) => x.id)

    const containerRef = useRef<any>(null)

    const [invisibleHeight, setInvisibleHeight, invisibleHeightRef] = useCurrentState(0)
    const [isDraggingInvisible, setIsDraggingInvisible, isDraggingInvisibleRef] = useCurrentState<null | { x: number; y: number }>(null)

    const [, updateState] = useState({})
    const forceUpdate = useCallback(() => updateState({}), [])
    useEffect(() => {
        const mouseDown = (e: MouseEvent) => {
            if (isDraggingRef.current || e.button !== 0) {
                setIsDraggingInvisible(null)
                return
            }
            if (!containerRef.current || !containerRef.current.contains(e.target)) {
                setIsDraggingInvisible(null)
                return
            }
            e.preventDefault()
            document.getSelection()?.removeAllRanges()
            window.getSelection()?.removeAllRanges()
            const container = containerRef.current.getBoundingClientRect()

            const x = Math.floor((e.clientX - container.left) / cellWidthRef.current) * cellWidthRef.current
            const y = Math.round((e.clientY - container.top) / cellHeight) * cellHeight
            setIsDraggingInvisible({ x, y })
            setInvisibleHeight(0)
        }
        const mouseMove = (e: MouseEvent) => {
            if (!isDraggingInvisibleRef.current || isDraggingRef.current || e.button !== 0) {
                setIsDraggingInvisible(null)
                return
            }
            e.preventDefault()
            document.getSelection()?.removeAllRanges()
            window.getSelection()?.removeAllRanges()
            const container = containerRef.current.getBoundingClientRect()
            const currentY = e.clientY - container.top
            const height = Math.abs(currentY - isDraggingInvisibleRef.current.y)
            setInvisibleHeight(height)
        }
        const mouseUp = (e: MouseEvent) => {
            if (!isDraggingInvisibleRef.current) {
                setIsDraggingInvisible(null)
                return
            }
            // console.log(isDraggingRef.current.x, isDraggingRef.current.y, invisibleHeightRef.current)
            // TODO: pixelsToDate depends on firstDay, if first day changes, day will be wrong
            const dateStart = pixelsToDate(isDraggingInvisibleRef.current.x, isDraggingInvisibleRef.current.y)
            const duration = pixelsToDuration(invisibleHeightRef.current)
            const dateEnd = roundToNearest30(dateStart + duration).getTime()
            const actualDuration = dateEnd - dateStart
            if (actualDuration < MS_PER_HALF_HOUR / 2) {
                setIsDraggingInvisible(null)
                return
            }
            handleDoubleClickDate?.({ dateStart, dateEnd })
            setIsDraggingInvisible(null)
            setInvisibleHeight(0)
        }
        window.addEventListener('resize', forceUpdate)
        window.addEventListener('mousedown', mouseDown)
        window.addEventListener('mousemove', mouseMove)
        window.addEventListener('mouseup', mouseUp)
        forceUpdate()
        return () => {
            window.removeEventListener('resize', forceUpdate)
            window.removeEventListener('mousedown', mouseDown)
            window.removeEventListener('mousemove', mouseMove)
            window.removeEventListener('mouseup', mouseUp)
        }
    }, [firstDay])

    // ref from grid
    const dragAreaRef = (node: HTMLDivElement | null) => {
        containerRef.current = node
        const width = (node?.clientWidth || 0) / days // DON'T round this, must be exact
        const height = node?.clientHeight || 0
        if (cellWidth !== width) setCellWidth(width) // TODO: add back
    }

    /**
     * helpers
     */
    function pixelsToDuration(px: number) {
        const actualCellHeight = cellHeight || DEFAULT_CELL_HEIGHT
        return Math.round((px * MS_PER_HALF_HOUR) / actualCellHeight)
    }

    function pixelsToDate(x: number, y: number) {
        const day = Math.floor((x + 1) / cellWidthRef.current) // can't drag before cellWidth is loaded, so shouldn't ever be 0
        const ms = pixelsToDuration(y)
        return roundToNearest30(addDays(firstDay, day).getTime() + ms).getTime()
    }

    // TODO: might have to remake multi-day events
    function updateDate(id: string, dateStart: number, dateEnd: number) {
        editCalItem(id, { dateStart, dateEnd })
        handleEventChange(id, { dateStart, dateEnd })
    }

    // TODO: recurring
    function handleMove(event: CalendarEvent, newStart: number) {
        const ogEvent = eventSet.get(event.id)
        if (isRecurringEvent(ogEvent) && !ogEvent.cron.startsWith('rate')) {
            // move recurring event
            const { cron } = ogEvent

            //#region init variables
            const curEvent = ogEvent.id === eventSet.getParent(event.id).id ? event : eventSet.getParent(event.id)
            if (curEvent.dateStart === newStart) return
            const beforeMoveDate = new Date(curEvent.dateStart),
                movedDate = new Date(newStart)
            const ogDayOfWeek = beforeMoveDate.getDay() + 1,
                movedDayOfWeek = movedDate.getDay() + 1
            const cronParser = new EventCronParser(cron, ogEvent.dateStart, ogEvent.dateEnd)
            //#endregion

            cronParser.setUTCHours([movedDate.getUTCHours()], [movedDate.getUTCMinutes()], {
                preserveLocalDaysOfWeek: true,
                referenceDate: movedDate,
            }) // set hours first, then set day of week

            if (ogDayOfWeek !== movedDayOfWeek) {
                handleCronDayOfWeekChange(cronParser, ogDayOfWeek, movedDayOfWeek)
            }

            const updates = {
                cron: cronParser.getCron(),
                dateStart: newStart < new Date(ogEvent.dateStart).getTime() ? newStart : ogEvent.dateStart,
                // ...(isAfter(newStart + duration, ogEvent.dateEnd) && { dateEnd: newStart + duration }), // dateEnd is more strict limit then dateStart for recurring events
            }
            editCalItem(ogEvent.id, updates)
            handleEventChange(ogEvent.id, updates)
            return
        }
        const dateChange = newStart - new Date(event.dateStart).getTime() // in ms
        if (dateChange === 0) return
        updateDate(ogEvent.id, new Date(ogEvent.dateStart).getTime() + dateChange, new Date(ogEvent.dateEnd).getTime() + dateChange)
        putAchievement({ achievementId: 'calendar-event-drag-move' })
    }

    const resizeCallback = () => {
        putAchievement({ achievementId: 'calendar-event-resize' })
    }
    // TODO: handle recurring + multiday
    // for multi-day: simple resize original, for recurring: duration changes, nothing else
    // TODO: multi-day might involve remaking multi-day event
    function handleResize(event: CalendarEvent, dir: Direction, durationChange: number) {
        const ogEvent = eventSet.get(event.id)
        if (!ogEvent?.id) {
            throw new Error('og event id missing' + event.id)
        }

        const start = new Date(ogEvent.dateStart).getTime()
        const end = new Date(ogEvent.dateEnd).getTime()

        if (isRecurringEvent(ogEvent)) {
            // TODO, don't forget recurring multiday, ??? why does this even work, it shouldn't cuz all i change is duration, so shouldn't work for resize top
            const { cron } = ogEvent

            const cronParser = new EventCronParser(cron, ogEvent.dateStart, ogEvent.dateEnd)

            // doesn't work for rate()
            // const [minutes, hours, dayOfMonth, month, dayOfWeek, year, duration] = cron.split(' ')
            const curEvent = ogEvent.id === eventSet.getParent(event.id).id ? event : eventSet.getParent(event.id)
            if (dir === 'top') {
                const curDateStart = new Date(curEvent.dateStart)
                // console.log('curDateStart', curDateStart, 'durationChange', durationChange / MS_PER_HOUR, 'newDateStart', new Date(curDateStart.getTime() - durationChange))
                const newStart = roundToNearest30(new Date(curDateStart.getTime() - durationChange))
                // console.log('new Start', newStart)

                const newDuration = differenceInMilliseconds(curEvent.dateEnd, newStart)
                if (newDuration <= 0) return // throw new Error('event must have a duration')
                // const cronParser = new EventCronParser(cron)
                if (newStart.getDay() !== curDateStart.getDay()) {
                    handleCronDayOfWeekChange(cronParser, curDateStart.getDay() + 1, newStart.getDay() + 1)
                }
                cronParser.setUTCHours([newStart.getUTCHours()], [newStart.getUTCMinutes()], {
                    preserveLocalDaysOfWeek: true,
                    referenceDate: newStart,
                })
                cronParser.setDuration(newDuration)

                const updates = {
                    cron: cronParser.getCron(),
                }
                // TODO: Bug resizing top of recurring event doesn't work for rate expressions
                // console.log('updates', cron, updates.cron, cronParser.earliestDate, new Date(ogEvent.dateStart))
                editCalItem(ogEvent.id, updates)
                handleEventChange(ogEvent.id, updates)
            } else if (dir === 'bottom') {
                const newEnd = roundToNearest30(new Date(new Date(curEvent.dateEnd).getTime() + durationChange))
                const newDuration = differenceInMilliseconds(newEnd, curEvent.dateStart)
                if (newDuration <= 0) return // throw new Error('event must have a duration')
                cronParser.setDuration(newDuration)
                const updates = {
                    cron: cronParser.getCron(),
                }
                editCalItem(ogEvent.id, updates)
                handleEventChange(ogEvent.id, updates)
                // updateDate(ogEvent.id, dateStart, dateEnd)
            }
            resizeCallback()
            return
        }
        // for NOT RECURRING events
        const dateStart = new Date(dir === 'top' ? roundToNearest30(start - durationChange) : start).getTime()
        const dateEnd = new Date(dir === 'bottom' ? roundToNearest30(end + durationChange) : end).getTime()

        if (dateStart >= dateEnd) {
            console.error('dateStart >= dateEnd', dateStart, dateEnd)
        }
        updateDate(ogEvent.id, dateStart, dateEnd)
        resizeCallback()
    }

    const isGoodCellLayoutKey = (key: string | undefined): key is string => !(!key || key[0] !== '0')
    const keyToEvent = (key: string) => idMap[key.substring(2)]

    const checkDuplicates = async (cellLayout: (string | undefined)[]) => {
        if (!handleRemoveItem) return
        const dupliates = groupBy(cellLayout.filter((key) => isGoodCellLayoutKey(key)) as string[], (key) => {
            const event = keyToEvent(key)
            return [event.name, event.dateStart, event.dateEnd, event.category, event.cron, event.priority, event.location].join('#')
        })
        for (const dup of Object.entries(dupliates)) {
            const [_, values] = dup
            if (values.length < 2) continue
            const events = values.map((key) => keyToEvent(key))
            const res = await Swal.fire({
                title: `Delete ${values.length - 1} duplicate copies of event?`,
                text: events.map((e) => e.name).join(', '),
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: `Yes, delete ${values.length - 1} duplicates`,
            })
            if (res.isConfirmed) {
                events
                    .slice(1)
                    .map((e) => e.id)
                    .forEach(handleRemoveItem)
            }
        }
    }

    function createRndProps(layout: EventLayout, frontLayout?: EventLayout) {
        // frontLayout is only passed in for long events (>=6h), frontLayout is shortLayout
        const props: { rndProps: any; event: CalendarEvent; style?: any; isLong: boolean }[] = []
        layout?.forEach((hours, day) => {
            hours.forEach((cellLayout, halfhour) => {
                checkDuplicates(cellLayout)

                cellLayout.forEach((key, pos) => {
                    if (!isGoodCellLayoutKey(key)) return
                    const id = key.substring(2)

                    const event = { ...idMap[id] }

                    const dateStart = new Date(event.dateStart),
                        dateEnd = new Date(event.dateEnd)
                    const duration = dateEnd.getTime() - dateStart.getTime()
                    const pxFromCellTop = (cellHeight * (dateStart.getMinutes() % 30)) / 30 // how many minutes from top

                    const rndProps = {
                        key: id + `.pos-${day}/${halfhour}/${pos}`,
                        bounds: 'parent',
                        position: {
                            x: cellWidth * day + cellWidth * (pos / cellLayout.length), // + pos / cellLayout.length * cellWidth, // x is day, need width of parent
                            y: pxFromCellTop + cellHeight * halfhour, // y is hour NOTE: cell height must include border width
                        },
                        size: {
                            width: cellWidth / cellLayout.length,
                            height: cellHeight * (duration / MS_PER_HALF_HOUR),
                        },
                        dragGrid: [cellWidth, cellHeight], // use width of container as width for drag container
                        resizeGrid: [cellWidth / cellLayout.length, cellHeight], // cannot use 0, or will not work (weird bug)
                        enableResizing: {
                            top: true,
                            right: false,
                            bottom: true,
                            left: false,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false,
                        },
                        dragAxis: 'both',
                        onDragStart: () => {
                            setIsDragging?.(true)
                        },
                        onDragStop: (e: any, { lastX, lastY, deltaX, deltaY }: DraggableData) => {
                            setIsDragging(false)
                            handleMove(event, pixelsToDate(lastX, lastY))
                        },
                        onResizeStop: (e: MouseEvent | TouchEvent, dir: any, ref: any, delta: any, position: any) => {
                            setIsDragging(false)
                            if (dir !== 'top' && dir !== 'bottom') throw new Error('dir wrong')
                            handleResize(event, dir, pixelsToDuration(delta.height))
                        },
                        onResizeStart: () => {
                            setIsDragging?.(true)
                        },
                    }

                    // placement of name for longEvents (since they can be covered by shortEvents) <-- prob not best place to put this, but since i'm passing props here ig its fine?
                    let nameOffsetPx = 0
                    if (frontLayout) {
                        let i = 0
                        while (halfhour + i < 48 && frontLayout[day][halfhour + i].length > 0) i++
                        nameOffsetPx = halfhour + i == 48 ? 0 : i * cellHeight // if no space at all (VERY UNLIKELY) just deal with it being hidden, not my problem
                    }
                    const ogEvent: CalendarEvent = eventSet.get(id)
                    // const isLong = isLongEvent(ogEvent)

                    props.push({ rndProps, event, isLong: !!frontLayout, style: { ...(frontLayout && { paddingTop: nameOffsetPx }) } })
                })
            })
        })
        return props
    }

    const rndProps = useMemo(() => {
        // !!! not sure if i need useMemo
        return [...createRndProps(shortLayout), ...createRndProps(longLayout, shortLayout)]
    }, [events, cellWidth, cellHeight])

    return {
        dragAreaRef,
        rndProps,
        cellWidth,
        invisibleProps: {
            style: {
                position: 'absolute',
                top: isDraggingInvisible?.y || 0,
                left: isDraggingInvisible?.x || 0,
                width: cellWidth,
                height: invisibleHeight,
                backgroundColor: token('colors.$overlay1'),
            },
        },
        // invisibleProps: {
        //     position: { x: invisibleX, y: invisibleY },
        //     size: !isDraggingInvisible ? { width: cellWidth, height: gridHeight } : { width: cellWidth, height: invisibleHeight },
        //     style: { backgroundColor: isDraggingInvisible ? 'pink' : 'transparent' },
        //     enableResizing: { top: false, right: false, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false },
        //     // bounds: 'parent',
        //     onDragStart: (e: any, props: any) => {
        //         console.log('drag start', props)
        //         setIsDraggingInvisible(true)
        //         setInvisibleHeight(100)
        //     },
        //     onDragStop: (e: any, { lastX, lastY, deltaX, deltaY, x, y, ...props }: DraggableData) => {
        //         console.log("invisible drag stop", new Date(pixelsToDate(0, 0)), new Date(pixelsToDate(lastX, lastY)))
        //         console.log({ lastX, lastY, deltaX, deltaY, x, y })
        //         setIsDraggingInvisible(false)
        //         // handleMove(event, pixelsToDate(lastX, lastY));
        //         const dateStart = pixelsToDate(0, 0)
        //         const dateEnd = pixelsToDate(lastX, lastY)
        //         if (dateStart >= dateEnd) return
        //         handleAddItem?.({
        //             name: "TEST",
        //             dateStart: pixelsToDate(0, 0),
        //             dateEnd: pixelsToDate(lastX, lastY),
        //             category: '/',
        //             completed: 0,
        //             type: 'event',
        //         })
        //         setInvisibleX(0)
        //         setInvisibleY(0)
        //     },
        //     onDrag: (e: any, { x, y, lastX, lastY, deltaX, deltaY }: DraggableData) => {
        //         setInvisibleX(x)
        //         setInvisibleY(y)
        //         setInvisibleHeight(Math.abs(lastY))
        //     }
        // }
    }
}

function roundToNearest30(date: Date | number) {
    return roundToNearestMinutes(date, { nearestTo: 30 })
}
