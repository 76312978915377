'use client'
import { Popover } from '@/components/common'
import React, { memo, useState } from 'react'
import { ErrorText, formStyles } from '../forms/styles'
import { Button, Input } from '@planda/design-system'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SingleHabit, SingleHabitSchema } from './types'
import { css } from 'styled-system/css'
import { usePutHabitMutation } from '@/redux/features/api'
import EmojiPicker from 'emoji-picker-react'
import { iconButton } from 'styled-system/recipes'
import { getDefaultHabit } from './utils'
import DayOfTheWeek from '../forms/item/components/DayOfTheWeek'
import ManualInputArray from '../forms/components/ManualInputArray'
import { difference, intersection } from 'lodash'

const HabitFormPopover = ({ children, defaultValues }: { children: JSX.Element; defaultValues?: Partial<SingleHabit> }) => {
    const [put] = usePutHabitMutation()
    const [openEmojiPicker, setOpenEmojiPicker] = useState(true)
    const [open, setOpen] = useState(false)
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm({
        defaultValues: { ...getDefaultHabit(defaultValues) },
        resolver: zodResolver(SingleHabitSchema),
    })
    const emoji = watch('symbol')
    const isEditingExisting = !!defaultValues?.id

    return (
        <Popover className={css({ maxWidth: '100vw' })} open={open} setOpen={setOpen} trigger={children}>
            <form
                onSubmit={handleSubmit((values) => {
                    put(values)
                    setOpen(false)
                })}
                className={css(formStyles.raw(), { background: 'inherit', maxWidth: '100%', fontSize: '$2' })}
            >
                <h2>{isEditingExisting ? 'Edit' : 'Create'} a habit</h2>
                <Input {...register('name')} placeholder="ex. Drink water" title="Habit name:" label={'Short name:'}>
                    {errors.name?.message && <ErrorText>{errors.name?.message}</ErrorText>}
                </Input>
                <ManualInputArray
                    labelAlign="left"
                    name={'targets'}
                    register={register}
                    control={control}
                    title={'Target:'}
                    empty={{ target: '', daysOfWeek: [] }}
                    properties={['target', 'daysOfWeek']}
                    errors={errors}
                    hideBullets
                    alignListItems="left"
                    addText="Add target variation"
                    inputRenderer={({ lineName, property, allLines }) => {
                        if (property === 'target') {
                            return (
                                <Input
                                    // @ts-expect-error
                                    {...register(lineName)}
                                    placeholder="ex. Drink 7L a day"
                                    title="Habit target:"
                                    label={'Target:'}
                                >
                                    {errors.target?.message && <ErrorText>{errors.target?.message}</ErrorText>}
                                </Input>
                            )
                        }
                        if (property === 'daysOfWeek') {
                            return (
                                <DayOfTheWeek
                                    onChangeCallback={(newValues) => {
                                        allLines?.forEach((properties) => {
                                            const { name, value: obj } = properties[1]
                                            if (name === lineName) return
                                            const value = obj.daysOfWeek
                                            const sameValues = intersection(newValues, value)
                                            if (sameValues.length === 0) return
                                            // @ts-expect-error
                                            setValue(name, difference(value, sameValues))
                                        })
                                    }}
                                    dayOfWeekAsNumber
                                    key={lineName}
                                    control={control}
                                    errors={errors}
                                    name={lineName}
                                />
                            )
                        }
                        return null
                    }}
                />
                {/* <Input {...register('target')} placeholder="ex. Drink 7L a day" title="Habit target:" label={'Target:'}>
                    {errors.target?.message && <ErrorText>{errors.target?.message}</ErrorText>}
                </Input> */}
                <Controller
                    name="symbol"
                    control={control}
                    render={({ field }) => (
                        <>
                            <EmojiPicker
                                className={css({
                                    '--epr-emoji-size': '20px',
                                    '& li': { margin: '0' },
                                    maxWidth: 500,
                                })}
                                reactions={['1f6b0', '1f4aa', '1f957', '1f6cc', '23f0', '1f4a7', '1f3b8', '1f3c0', '1f48a', '1faa5']}
                                style={{
                                    // @ts-ignore
                                    '--epr-emoji-size': '20px',
                                    '--epr-header-padding': '8px var(--epr-horizontal-padding)',
                                    '--epr-preview-height': '40px',
                                }}
                                open={openEmojiPicker}
                                reactionsDefaultOpen
                                onEmojiClick={(emoji) => {
                                    // console.log('emoji', emoji.unified)
                                    field.onChange(emoji.emoji)
                                    setOpenEmojiPicker(false)
                                }}
                                width={300}
                                height={300}
                            />
                            {!openEmojiPicker ? (
                                <button className={iconButton({ variant: 'ghost' })} type="button" onClick={() => setOpenEmojiPicker(true)}>
                                    {emoji}
                                </button>
                            ) : null}
                        </>
                    )}
                />
                <Button width={'max-content'} disabled={isSubmitting} type="submit">
                    {isEditingExisting ? 'Save' : 'Create'}
                </Button>
                <ul className={css({ maxWidth: 500, fontSize: 'small' })}>
                    Tips:
                    <li>Do you feel adversion to your target? If yes, lower the bar, make it easier.</li>
                    {/* <li>Remember that gradual change is lasting change. Start small.</li> */}
                    <li>The smaller the change, the more likely you are to succeed at making it.</li>
                    <li>We&apos;ll remind you to raise the bar after 20 sucesses in a row.</li>
                </ul>
                {/* <Input label='Name' placeholder="Habit Name" /> */}
            </form>
        </Popover>
    )
}

export default memo(HabitFormPopover)
