'use client'
import { format, isSameDay } from 'date-fns'
import React from 'react'
import Dot from './Dot'
import { css, cx } from 'styled-system/css'
import { HabitEntry, SingleHabit } from './types'
import { finalColumnDot, getDot } from './utils'

const cell = css.raw({ paddingInline: '$2', border: '1px solid {colors.$gray4}' })
const HabitRow = ({
    day,
    habits,
    tr,
    td,
    entriesMap = {},
}: {
    day: number
    habits: SingleHabit[]
    tr: string
    td: string
    entriesMap: Record<string, HabitEntry[]> | undefined
}) => {
    const entries = habits.map((habit) => {
        // TODO: remove destructuring, just use `habit`
        const { id } = habit
        return {
            id,
            entry: entriesMap[id]?.find((entry) => isSameDay(entry.date, day)),
            habit,
        }
    })

    return (
        <tr className={tr} key={day}>
            <td className={cx(td, css(cell))}>{format(day, 'MMM d')}</td>
            {entries.map(({ entry, id, habit }) => {
                const dot = getDot(entry, habit, day)
                return (
                    <td className={cx(td, css(cell))} key={entry?.id || id + day}>
                        <Dot date={day} habitId={id} dot={dot} />
                    </td>
                )
            })}
            <td className={cx(td, css(cell))}>
                <Dot date={day} isShiny dot={finalColumnDot(entries, day)} />
            </td>
        </tr>
    )
}

export default HabitRow
