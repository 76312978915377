'use client'
// import { Toolbar } from "@/components/specific/toolbar/Toolbar";
import { css } from 'styled-system/css'
import { AnimatePresence, motion } from 'framer-motion'
import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { styled } from 'styled-system/jsx'
import { useGlobalPanda } from '@/components/specific/toolbar/components/useShowPanda'
import { useHotkeys } from 'react-hotkeys-hook'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { setViewMode } from '@/redux/features/view-mode/viewModeSlice'
import { useGetFeatureAccessQuery, useGetGeneralThemeCustomizationsQuery, useGetShareLinkSettingsQuery } from '@/redux/features/api'
import { useSession } from 'next-auth/react'
import { useRouter, useSearchParams } from 'next/navigation'
// import { OpenGlobalFormContext, useItemForm } from 'src/context/useGlobalForm'
import dynamic from 'next/dynamic'
import { openItemForm, setOpenItemForm } from '@/redux/features/temporaryStateSlice'
import { setCustomCategoryColorsGlobally } from '@/utils/categories'
import { ThemeCustomization } from '@/types/theme'
import GeneralPanda from '@/features/easter-eggs/choose/GeneralPanda'
import Woodpecker from '@/components/common/loaders/Woodpecker'
import DemoInfoPanel from './DemoInfoPanel'
import Toolbar from '@/components/specific/toolbar/Toolbar'
import useDetectOffline from '@/hooks/useDetectOffline'

const ItemForm = dynamic(() => import('src/features/forms/item'))
const LazyLoadAuthed = dynamic(() => import('./LazyLoadAuthed'))
const ForwardedRefLazyLoadAuthed = forwardRef((props, ref) => <LazyLoadAuthed {...props} forwardedRef={ref} />)
ForwardedRefLazyLoadAuthed.displayName = 'ForwardedRefLazyLoadAuthed'

const Lottie = dynamic(() => import('react-lottie-player'), {
    loading: () => <Woodpecker />,
    ssr: false,
})
// Client Components:
// const Toolbar = dynamic(() => import('@/components/specific/toolbar/Toolbar').then((m) => m.Toolbar), {
//     loading: () => <div className={css({ width: 41, height: '100%' })}></div>,
// })

const animation = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 1,
        },
    },
    transition: {
        duration: 1,
        ease: 'easeInOut',
    },
}

export default function AuthLayout({
    children,
    themeCustomizations: initialThemeCustomizations,
}: {
    children: React.ReactNode
    themeCustomizations?: ThemeCustomization
}) {
    const viewMode = useAppSelector((state) => state.viewMode)
    const openForm = useAppSelector((state) => state.temporaryState.openForm)
    const defaultValues = useAppSelector((state) => state.temporaryState.defaultFormItems)
    useGetFeatureAccessQuery()
    const dispatch = useAppDispatch()
    const setOpenForm = useCallback((open: boolean) => dispatch(setOpenItemForm(open)), [dispatch])
    useDetectOffline()
    // const dispatch = useAppDispatch();
    const router = useRouter()
    // const { viewMode, checkIfViewMode, linkSettings, endViewMode } = useDemo() // technically share mode is a type of demo mode
    const {} = useSession({
        required: true,
        onUnauthenticated() {
            console.log('in onUnauthenticated')
            if (!viewMode.mode) {
                router.push('/about')
            }
            //dispatch(endViewMode())
        },
    })

    const searchParams = useSearchParams()!
    const sharing = searchParams.get('sharing')
    const sharingBy = searchParams.get('sharingby')
    if (sharing && sharingBy && viewMode.mode !== 'share') {
        dispatch(setViewMode({ mode: 'share', shareLinkUrlInfo: { sharingId: sharing, sharingBy: sharingBy } }))
    }

    const constraintsRef = useRef(null)
    const { pandaAnimation } = useGlobalPanda()

    useHotkeys('ctrl+q', () => {
        dispatch(openItemForm())
    }) // TODO: use mutate from useSWRConfig for list + day views

    const { data: themeCustomization } = useGetGeneralThemeCustomizationsQuery()

    useLayoutEffect(() => {
        setCustomCategoryColorsGlobally(initialThemeCustomizations)
    }, [initialThemeCustomizations])

    useEffect(() => {
        // TODO: move this to lazy load, and use server components to set this initially
        setCustomCategoryColorsGlobally(themeCustomization)
    }, [themeCustomization])

    return (
        <main ref={constraintsRef} className={css({ height: '100vh', display: 'flex', boxSizing: 'border-box' })}>
            {' '}
            {/* <Suspense fallback={}> */}
            {/* <Suspense fallback={}> */}
            <Toolbar />
            {/* </Suspense> */}
            <div className={css({ display: 'flex', flex: '1', position: 'relative', minWidth: 0 })}>
                {children}
                <DemoInfoPanel ref={constraintsRef} />
                <ForwardedRefLazyLoadAuthed ref={constraintsRef} />
                <AnimatePresence initial={true} mode="wait">
                    {pandaAnimation && (
                        <motion.div
                            className={css({
                                position: 'fixed',
                                zIndex: 1000,
                                maxHeight: '25%',
                                maxWidth: 'clamp(100px, 15%, 300px)',
                                bottom: '5vh',
                                left: '10vh',
                            })}
                            {...animation}
                        >
                            <PandaLottie play animationData={pandaAnimation} loop />
                        </motion.div>
                    )}
                </AnimatePresence>

                <ItemForm open={openForm} setOpen={setOpenForm} defaultValues={defaultValues} referenceDate={defaultValues?.referenceDate} />
                <GeneralPanda />
            </div>
        </main>
    )
}


const PandaLottie = styled(Lottie, {
    base: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
})
