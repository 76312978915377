import { ToggleGroup } from '@/components/common'
import React from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import FormError from './FormError'
import { DAYS_OF_WEEK } from '@/constants/date'

const DOW_GROUP_ITEMS = DAYS_OF_WEEK.map((day: string, i: number) => ({ value: i.toString(), label: day[0] }))

/** 0-indexed if number */
const DayOfTheWeek = ({
    control,
    errors,
    name = 'daysOfWeek',
    dayOfWeekAsNumber = false,
    onChangeCallback,
}: {
    control: Control<any>
    errors: Partial<FieldErrorsImpl<any>>
    name?: string
} & (
    | { dayOfWeekAsNumber: true; onChangeCallback?: (value: number[]) => void }
    | { dayOfWeekAsNumber: false; onChangeCallback?: (value: string[]) => void }
    | { dayOfWeekAsNumber?: undefined; onChangeCallback?: () => void }
)) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <>
                    <ToggleGroup
                        {...field}
                        value={field.value.map((v: number) => v.toString())}
                        onValueChange={(value) => {
                            const dow = dayOfWeekAsNumber ? value.map((v: string) => parseInt(v)) : value
                            field.onChange(dow)
                            // @ts-expect-error
                            onChangeCallback?.(dow)
                        }}
                        type={'multiple'}
                        items={DOW_GROUP_ITEMS}
                        error={<FormError errors={errors} name={name} />}
                    />
                </>
            )}
        />
    )
}

export default DayOfTheWeek
