import { Control, useFieldArray } from 'react-hook-form'
import FieldArray, { FieldArrayProps } from './FieldArray'
import { Button } from '@planda/design-system'
import { cloneDeep } from 'lodash'

const ManualInputArray = ({
    control,
    name,
    empty,
    addText = 'Add',
    ...props
}: {
    control: Control<any>
    empty: any
    addText?: string
} & FieldArrayProps) => {
    const { remove, fields, append } = useFieldArray({
        control,
        name,
    })

    return (
        <>
            <FieldArray fields={fields} remove={remove} name={name} {...props} />
            <Button type="button" width={'max-content'} onClick={() => append(cloneDeep(empty))}>
                {addText}
            </Button>
        </>
    )
}

export default ManualInputArray
